import {call, select, put, takeEvery, takeLeading, delay} from 'redux-saga/effects';

import userManager from '../utils/userManager';
// import {fetchSettings} from './minervaSettings';
// ////import {updateUserDetails} from './updateUserDetails';
// import {navigate} from 'lit-redux-router';

const _getAcrValues = (settings) => {
    const values = [];

    // preferredIdp
    if (settings.preferredIdp) {
        values.push(`idp:${escape(settings.preferredIdp)}`);
    }

    // tenant
    if (settings.tenant) {
        values.push(`tenant:${settings.tenant}`);
        values.push(`sdbTenant:${settings.tenant}`);
    } else {
        values.push(`tenant:${settings.portalIdentifier}`);
    }

    values.push(`portalIdentifier:${settings.portalIdentifier}`);

    // loginDomains
    if (settings.loginDomains) {
        values.push(`loginDomains:${settings.loginDomains}`);
    }

    // forceDomain
    if (settings.forceDomain) {
        values.push(`forceDomain:${settings.forceDomain}`);
    }

    // externalCss
    if (settings.loginCssUrl) {
        values.push(`externalCss:${escape(settings.loginCssUrl)}`);
    }

    // externalCss
    if (settings.replacementNameIdentifierClaim) {
        values.push(`replaceClaim:${escape(settings.replacementNameIdentifierClaim)}`);
    }

    return values.join(' ');
};

function* doSignIn() {
    const state = yield select();

    yield call([userManager, userManager.signinRedirect], {
        acr_values: _getAcrValues(state.minervaSettings),
        state: {url: window.location.pathname + window.location.search},
    });
}

function* doSignOut() {
    yield call([userManager, userManager.signoutRedirect]);
}

function* doSigninSilent() {
    const state = yield select();

    try {
        yield call([userManager, userManager.signinSilent], {
            acr_values: _getAcrValues(state.minervaSettings),
        });

        yield put({type: 'SIGNIN_SILENT_FULFILLED'});
    } catch (error) {
        yield put({type: 'SIGNIN_SILENT_REJECTED', error});
    }
}

function* doUserFound() {}

export function* signIn() {
    yield takeEvery('SIGNIN', doSignIn);
    yield takeLeading('SIGNINCALLBACK', doSignInCallback);
    yield takeEvery('SIGNEDIN', doSignedIn);
    yield takeEvery('SIGNINSILENT', doSigninSilent);

    yield takeEvery('redux-oidc/USER_FOUND', doUserFound);

    yield takeEvery('SIGNOUT', doSignOut);
}

function* doSignInCallback() {
    try {
        const result = yield call([userManager, userManager.signinRedirectCallback]);

        console.log('Authenticate callback success');
        yield put({type: 'SIGNEDIN', url: result.state.url});
    } catch (error) {
        console.log('Authenticate callback FAIL');
        window.location.href = window.location.origin;
    }

    // Debounce because state changes can trigger multiple
    // SIGNINCALLBACK actions.
    // Combines with takeLeading, so no new calls will
    // come in while this one is waiting
    yield delay(250);
}

function* doSignedIn(action) {
    yield put({type: 'MINERVASETTINGS_REQUESTED'});
    // TODO: find a way to updateUserDetails from here?
    // ////await dispatch(updateUserDetails());

    yield put({type: 'SIGNIN_FULFILLED'});

    // console.error('replace navigate call');

    window.location.href = action.url;
    // window.history.pushState({}, null, action.url);
    // yield put(navigate(action.url));
}
