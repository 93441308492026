import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import {store} from './store.js';
import {connect} from 'pwa-helpers/connect-mixin.js';

class AcaSigninAutoRedirect extends connect(store)(PolymerElement) {
    static get is() {
        return 'aca-signin-auto-redirect';
    }

    static get template() {
        return html``;
    }

    static get properties() {
        return {};
    }

    stateChanged(state) {}

    connectedCallback() {
        super.connectedCallback();

        store.dispatch({type: 'SIGNIN'});
    }
}

window.customElements.define(AcaSigninAutoRedirect.is, AcaSigninAutoRedirect);
