const app = (state = {}, action) => {
    switch (action.type) {
        case 'PLANNING_REPORT_SETPARAMS': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }

        case 'PLANNING_REPORT_LOADED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        case 'PLANNING_REPORT_LOADING': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        case 'REPORT_FETCH_BUSY': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        case 'REPORT_FETCH_BUSY_DONE': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default app;
