import {AppLocalizeBehavior} from './app-localize-behavior.js';
import moment from 'moment/dist/moment.js';
import 'moment/dist/locale/nl.js';

import IntlMessageFormat from 'intl-messageformat';
window.IntlMessageFormat = IntlMessageFormat;

import locales from './locales.js';

let language = (navigator.language || navigator.browserLanguage || 'en').substring(0, 2);

language = language !== 'nl' && language !== 'en' ? 'en' : language;

moment.locale(language);

export const TcgLocalizeBehavior = [
    AppLocalizeBehavior,
    {
        created: function () {
            this.language = language;
            this.moment = moment;
            this.resources = locales;
        },
    },
];

export function tcgLocalizeBehaviorMixin(Base) {
    if (Base === null) {
        throw new Error('tcgLocalizeBehaviorMixin(Base) expects a base class');
    }

    return class TcgLocalizeElement extends Base {
        constructor() {
            super();
            this.language = language;
            this.moment = moment;
            this.resources = locales;
            this.formats = {};
        }

        static get properties() {
            return {
                language: {
                    type: String,
                },
                moment: {
                    type: Object,
                },
                resources: {
                    type: Object,
                },
                formats: {
                    type: Object,
                },
            };
        }

        localize(key, ...rest) {
            const proto = this.constructor.prototype;

            if (!proto.__localizationCache) {
                proto.__localizationCache = {messages: {}};
            }

            const translatedValue = this.resources[this.language][key];

            if (!translatedValue) {
                console.warn(`'Missing translation for key ${key}`);
            }

            const messageKey = key + translatedValue;
            let translatedMessage = proto.__localizationCache.messages[messageKey];

            if (!translatedMessage) {
                translatedMessage = new IntlMessageFormat(
                    translatedValue,
                    this.language,
                    this.formats,
                );
                proto.__localizationCache.messages[messageKey] = translatedMessage;
            }

            const args = {};

            for (let i = 0; i < rest.length; i += 2) {
                args[rest[i]] = rest[i + 1];
            }

            return translatedMessage.format(args);
        }
    };
}
