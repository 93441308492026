/* eslint-disable indent */
import moment from 'moment/dist/moment.js';

export const CLASSROOM_LOADPLANNINGS = 'CLASSROOM_LOADPLANNINGS';

export const loadPlannings = (courseId) => async (dispatch, getState) => {
    const {oidc, minervaSettings} = getState();
    const {portalApiUrl} = minervaSettings;

    dispatch({
        type: CLASSROOM_LOADPLANNINGS,
        async payload() {
            const res = await fetch(`${portalApiUrl}/api/1.0/planningsbycourseid/${courseId}`, {
                headers: getAuthorizationHeaders(oidc.user),
            });

            const data = await res.json();
            const latestPlannings = (data.results || []).filter(endedInLastTwoYears);

            return {
                plannings: latestPlannings,
            };
        },
    });
};

const endedInLastTwoYears = (planning) =>
    moment(planning.endDateTime).isAfter(moment().subtract(2, 'year'));

const getAuthorizationHeaders = (user) => {
    return user ? {Authorization: `Bearer ${user.access_token}`} : {};
};
