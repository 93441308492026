export const GET_SCORM_RESULT = 'GET_SCORM_RESULT';
export const UPDATE_SCORM_RESULT = 'UPDATE_SCORM_RESULT';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const getScormResult = (itemId, targetId) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const catalogApiUrl = getState().minervaSettings.catalogApiUrl;

    dispatch({
        type: GET_SCORM_RESULT,
        async payload() {
            // Fetch the ScormResult from Raven
            const result = await fetch(
                `${catalogApiUrl}/api/scorm12/result/${targetId}/${itemId}`,
                {
                    method: 'GET',
                    headers: getHeaders(user),
                },
            );

            let scormResult = {};

            if (result.status === 200) {
                scormResult = await result.json();
            }

            return {
                scormResult,
                canResume: scormResult.entry === 'resume',
                isReview: scormResult.lessonMode === 'review',
            };
        },
    });
};

export const updateScormResult = (scormResult) => async (dispatch, getState) => {
    await dispatch({
        type: UPDATE_SCORM_RESULT,
        async payload() {
            return {
                scormResult,
                canResume: scormResult.entry === 'resume',
                isReview: scormResult.lessonMode === 'review',
            };
        },
    });
};
