import {all} from 'redux-saga/effects';
import {signIn} from './signInSaga.js';
import {settingSaga} from './minervaSettingsSaga.js';
import {planningReportSaga} from './planningReportSaga.js';
import {catalogSaga} from './catalogSaga.js';
import {reportSaga} from './reportSaga.js';
import {adminCatalogSaga} from './adminCatalogSaga.js';
import {assignmentCatalogSaga} from './assignmentCatalogSaga.js';
import {skillsTargetIdMapSaga} from './skillsTargetIdMapSaga.js';

function* rootSaga() {
    yield all([
        signIn(),
        settingSaga(),
        catalogSaga(),
        planningReportSaga(),
        reportSaga(),
        adminCatalogSaga(),
        assignmentCatalogSaga(),
        skillsTargetIdMapSaga(),
    ]);
}

export default rootSaga;
