/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import {
    REGISTRATION_LOAD,
    REGISTRATION_UPDATE,
    REGISTRATION_SAVE,
} from '../actions/registration.js';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${REGISTRATION_LOAD}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${REGISTRATION_LOAD}_FULFILLED`: {
            const {user, registrationInfo} = payload;

            return {
                ...state,
                loading: false,
                error: false,
                current: {...user},
                info: [...registrationInfo],
                dirty: false,
            };
        }
        case `${REGISTRATION_LOAD}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: payload,
                current: null,
                info: [],
            };
        case REGISTRATION_UPDATE: {
            const {key, changes} = payload;

            return {
                ...state,
                current: {
                    ...state.current,
                    registrations: state.current.registrations.some(
                        (o) => o.registrationInfoKey === key,
                    )
                        ? state.current.registrations.map((o) =>
                              o.registrationInfoKey === key ? (o, {...changes}) : o,
                          )
                        : [...state.current.registrations, changes],
                },
                dirty: true,
            };
        }
        case `${REGISTRATION_SAVE}_PENDING`:
            return {
                ...state,
                save: 'pending',
                error: false,
            };
        case `${REGISTRATION_SAVE}_FULFILLED`:
            return {
                ...state,
                save: 'success',
                error: false,
                dirty: false,
            };
        case `${REGISTRATION_SAVE}_REJECTED`:
            return {
                ...state,
                save: 'failed',
                error: payload,
            };
        default:
            return state;
    }
};

export default app;
