const initialState = {};

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'SKILLS_CATALOGITEMMAP_LOAD': {
            return {
                ...state,
                pending: true,
            };
        }

        case 'SKILLS_CATALOGITEMMAP_LOAD_DONE': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                pending: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default app;
