export const ADD_ASSESSOR = 'ADD_ASSESSOR';
export const REMOVE_ASSESSOR = 'REMOVE_ASSESSOR';
export const SET_CURRENT_CLUSTER_TYPE = 'SET_CURRENT_CLUSTER_TYPE';
export const HIDE_NOT_ASSIGNED_TOGGLE_FILTER = 'HIDE_NOT_ASSIGNED_TOGGLE_FILTER';
export const SELECT_USER_FUNCTION_FOR_FILTER = 'SELECT_USER_FUNCTION_FOR_FILTER';
export const SET_SKILL_FILTER_PARAMS_FOR_DEPARTMENTS = 'SET_SKILL_FILTER_PARAMS_FOR_DEPARTMENTS';
export const SET_SELECTED_FILTER_LABELS = 'SET_SELECTED_FILTER_LABELS';
export const SET_USER_SKILLS_HISTORY_VIEW = 'SET_USER_SKILLS_HISTORY_VIEW';

const getHeaders = (user) => {
    if (user) {
        return {
            Authorization: `Bearer ${user.access_token}`,
        };
    } else return {};
};

export const addAssessor = (user) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.skillsApiUrl}/api/6.0/settings/assessor/${minervaSettings.portalIdentifier}`;

    dispatch({
        type: ADD_ASSESSOR,
        async payload() {
            const response = await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(user),
            });

            const savedSettings = await response.json();

            return savedSettings;
        },
    });
};

export const removeAssessor = (idString) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.skillsApiUrl}/api/6.0/settings/assessor/${minervaSettings.portalIdentifier}?idString=${encodeURIComponent(idString)}`;

    dispatch({
        type: REMOVE_ASSESSOR,
        async payload() {
            const response = await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });

            const savedSettings = await response.json();

            return savedSettings;
        },
    });
};

export const setCurrentClusterType = (clusterType) => {
    return {
        type: SET_CURRENT_CLUSTER_TYPE,
        payload: clusterType,
    };
};

export const hideNotAssignedToogleFilter = (hideNotAssigned) => {
    return {
        type: HIDE_NOT_ASSIGNED_TOGGLE_FILTER,
        payload: hideNotAssigned,
    };
};

export const selectUserFunctionForFilter = (value) => {
    return {
        type: SELECT_USER_FUNCTION_FOR_FILTER,
        payload: value,
    };
};

export const setSkillFilterParamsForDepartments = (value) => {
    return {
        type: SET_SKILL_FILTER_PARAMS_FOR_DEPARTMENTS,
        payload: value,
    };
};

export const setSelectedFiltersLabels = (value) => {
    return {
        type: SET_SELECTED_FILTER_LABELS,
        payload: value,
    };
};

export const setUsersSkillsHistoryView = (value) => {
    return {
        type: SET_USER_SKILLS_HISTORY_VIEW,
        payload: value,
    };
};
