import {call, select, takeLatest, put} from 'redux-saga/effects';

function* doFetchReport(action) {
    const {minervaSettings, planningReport} = yield select();

    const reportData = {
        template: {
            shortid: action.templateId,
        },
        data: action.data,
        options: {localization: {language: action.language}},
    };

    const response = yield call(fetch, minervaSettings.reportApiUrl, {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + btoa('skills:skills'),
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
    });

    const file = yield call([response, response.blob]);

    if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, action.fileName);
    } else {
        // Others
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);

        a.href = url;
        a.download = action.fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    if (planningReport.downloading) {
        yield put({
            type: 'REPORT_FETCH_BUSY_DONE',
            payload: {
                ...planningReport,
                downloading: false,
            },
        });
    }
}

export function* reportSaga() {
    yield takeLatest('REPORT_FETCH', doFetchReport);
}
