import {LitElement} from 'lit';
import {store} from '../store.js';

class AuthenticateCallback extends LitElement {
    constructor() {
        super();

        console.log('Authenticate callback CONSTRUCTOR');
    }

    firstUpdated() {
        store.dispatch({type: 'SIGNINCALLBACK'});
    }
}

customElements.define('authenticate-callback', AuthenticateCallback);
