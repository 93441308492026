import {createUserManager} from '@competencegroup/redux-oidc-es6';
import OidcClient from 'oidc-client/index.js';

let useLocalStorage = false;

if (window.sessionStorage.getItem('uselocalstorage') === '1') {
    useLocalStorage = true;
}

if (window.location.search === '?uselocalstorage') {
    window.sessionStorage.setItem('uselocalstorage', '1');
    useLocalStorage = true;
} else {
    window.sessionStorage.removeItem('uselocalstorage');
}

const userManagerConfig = {
    client_id: getClientId(),
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
    response_type: 'token id_token',
    scope: 'openid name api api_tcg_claims idString email',
    authority: getAuthority(),
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: false,
    loadUserInfo: false,
    userStore: new OidcClient.WebStorageStateStore({
        store: useLocalStorage ? window.localStorage : window.sessionStorage,
    }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;

export function getClientId() {
    switch (window.location.hostname) {
        case 'localhost':
            return 'https://develop5.tcg-academy.nl';
        default:
            return `https://${window.location.hostname}`;
    }
}

export function getAuthority() {
    return [
        'localhost',
        'develop.tcg-minerva.nl',
        'test.tcg-minerva.nl',
        'develop.test-sdbleerplatform.nl',
        'test.test-sdbleerplatform.nl',
    ].indexOf(window.location.hostname.toLowerCase()) === -1
        ? 'https://sts.competence.biz/identity'
        : 'https://sts-dev.competence.biz/identity';
}
