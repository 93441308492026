/* eslint-disable operator-linebreak */
import moment from 'moment/dist/moment.js';

export const getPlanningInfo = (planning) =>
    moment(planning.startDateTime).isSame(planning.endDateTime, 'day')
        ? `${formatDate(planning.startDateTime)} ${formatTime(planning.startDateTime)} - ${formatTime(planning.endDateTime)}`
        : `${formatDate(planning.startDateTime)} t/m ${formatDate(planning.endDateTime)}`;

export const formatDate = (date) => (!date ? '' : moment.utc(date).local().format('LL'));

export const formatTime = (date) => (!date ? '' : moment.utc(date).local().format('LT'));

export const sortOnDate = (list, prop, descending) =>
    !descending
        ? [...list].sort((a, b) => new Date(a[prop]) - new Date(b[prop]))
        : [...list].sort((a, b) => new Date(b[prop]) - new Date(a[prop]));

export const getExpirationStatus = (date, ...args) => {
    const now = moment.utc();
    const almostExpired = moment().add(...args);

    return !date
        ? 'none'
        : moment(date).isBefore(now)
          ? 'expired'
          : moment(date).isBefore(almostExpired)
            ? 'almost-expired'
            : 'not-expired';
};
