export const HIDE_NOT_ASSIGNED_TRAININGS_TOGGLE_FILTER =
    'HIDE_NOT_ASSIGNED_TRAININGS_TOGGLE_FILTER';
export const SELECT_USER_FUNCTION_FOR_TRAININGS_FILTER =
    'SELECT_USER_FUNCTION_FOR_TRAININGS_FILTER';

export const hideNotAssignedTrainingsToogleFilter = (hideNotAssigned) => {
    return {
        type: HIDE_NOT_ASSIGNED_TRAININGS_TOGGLE_FILTER,
        payload: hideNotAssigned,
    };
};

export const selectUserFunctionForTrainingsFilter = (value) => {
    return {
        type: SELECT_USER_FUNCTION_FOR_TRAININGS_FILTER,
        payload: value,
    };
};
