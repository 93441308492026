export const FETCH_LEARNINGPLAN = 'FETCH_LEARNINGPLAN';
export const SIGNAL_LEARNINGPLAN = 'SIGNAL_LEARNINGPLAN';
export const NAVIGATE_PARENTFLOW = 'NAVIGATE_PARENTFLOW';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const fetchLearningPlan = (workflow) => async (dispatch, getState) => {
    const state = getState();
    const apiUrl = state.minervaSettings.portalApiUrl;
    const catalogApiUrl = state.minervaSettings.catalogApiUrl;
    const portalIdentifier = state.minervaSettings.portalIdentifier;

    if (!apiUrl) return;

    let instanceStack = state.learningPlan.instanceStack || [workflow.instanceId];

    if (workflow.newStart) {
        // fresh starts always start with a new instanceStack
        instanceStack = [workflow.instanceId];
    }

    if (instanceStack[0] !== workflow.instanceId) {
        instanceStack.push(workflow.instanceId);
    }

    dispatch({
        type: FETCH_LEARNINGPLAN,
        async payload() {
            const res = await fetch(
                `${apiUrl}/api/1.0/workflow/definition?instanceId=${workflow.instanceId}`,
                {
                    headers: getHeaders(state.oidc.user),
                },
            );

            const data = await res.json();

            const catalogItem = await fetch(
                `${catalogApiUrl}/api/catalogItem/${portalIdentifier}/${data.targetId}`,
                {
                    headers: getHeaders(state.oidc.user),
                },
            );
            const catalogData = await catalogItem.json();

            return {
                ...data,
                openScormInNewWindow: catalogData.openScormInNewWindow,
                instanceStack,
                instanceId: workflow.instanceId,
            };
        },
    });
};

export const navigateParentFlow = () => async (dispatch, getState) => {
    const state = getState();
    const apiUrl = state.minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const instanceStack = state.learningPlan.instanceStack;

    dispatch({
        type: NAVIGATE_PARENTFLOW,
        async payload() {
            instanceStack.pop();
            const newInstanceId = instanceStack[instanceStack.length - 1];

            const res = await fetch(
                `${apiUrl}/api/1.0/workflow/definition?instanceId=${newInstanceId}`,
                {
                    headers: getHeaders(state.oidc.user),
                },
            );

            const data = await res.json();

            return {
                ...data,
                instanceStack,
                instanceId: newInstanceId,
            };
        },
    });
};

export const signal = (workflowInstanceId) => async (dispatch, getState) => {
    const state = getState();
    const apiUrl = state.minervaSettings.portalApiUrl;

    dispatch({
        type: SIGNAL_LEARNINGPLAN,
        async payload() {
            const res = await fetch(
                `${apiUrl}/api/1.0/workflow/signal?instanceId=${workflowInstanceId}`,
                {
                    method: 'POST',
                    body: {},
                    headers: getHeaders(state.oidc.user),
                },
            );

            const data = await res.json();

            return {
                ...data, // just trackingRecords
            };
        },
    });
};
