import {html, css, LitElement, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';

import {ContextProvider} from '@lit/context';
import {gravatarUrl} from '@competencegroup/tcg-useritem/tcg-gravatar.js';

// NOTE: this polyfill is needed when the URLPattern doesn't exist (i.e. Firefox)
// if (!globalThis.URLPattern) {
// await import('urlpattern-polyfill');
// }
// SR: below works for iOS14 and lower, the above way of import does not.
import 'urlpattern-polyfill';

import '@polymer/app-route/app-location.js';
import '@competencegroup/tcg-signin/tcg-signin-aware.js';

import './components/authenticate-callback.js';
import './components/silent-renew-callback.js';

import {store} from './store.js';
import {connect} from 'pwa-helpers/connect-mixin.js';
import {Router} from '@lit-labs/router';
import 'wc-context-redux/store-context';

//import './aca-silent-signin-page';
import './aca-signin-page.js';
import './aca-app-update.js';
import '@vaadin/polymer-legacy-adapter/template-renderer.js';

import {VaadinStyleLoader} from '@sdblearning/sdb-learning-frontend/dist/src/vaadin-style-loader.js';
import {colors} from '@sdblearning/sdb-learning-frontend/dist/src/sdb-colors.js';

import('@microsoft/applicationinsights-web').then(
    (module) => {
        const appInsights = new module.ApplicationInsights({
            config: {
                instrumentationKey: '76607fcc-a4ec-4d34-8233-572fd78923cf',
                enableAutoRouteTracking: true,
                enableUnhandledPromiseRejectionTracking: true,
                /* ...Other Configuration Options... */
            },
        });

        window.appInsights = window.appInsights || appInsights;

        // Add telemetry initializer to filter out specific URLs
        appInsights.addTelemetryInitializer((envelope) => {
            if (envelope.baseType === 'RemoteDependencyData') {
                const dependency = envelope.baseData;

                // Exclude specific URL from being tracked
                if (
                    dependency.target &&
                    dependency.target.indexOf('https://js.monitor.azure.com/') !== -1
                ) {
                    return false; // Stop this telemetry from being sent
                }
            }

            return true; // Allow other telemetry to pass through
        });

        appInsights.loadAppInsights();

        // Define a telemetry initializer to ignore specific dependencies

        appInsights.addTelemetryInitializer(function (envelope) {
            // Check if it's a dependency telemetry item

            if (envelope.baseType === 'RemoteDependencyData') {
                var dependencyData = envelope.baseData;

                // Exclude telemetry items that match a specific URL
                if (
                    dependencyData.target &&
                    dependencyData.target.indexOf('https://js.monitor.azure.com') !== -1
                ) {
                    // Return false to prevent this telemetry from being sent
                    return false;
                }
            }

            // Let other telemetry pass through
            return true;
        });
    },
    (err) => {
        console.log(err);
    },
);

@customElement('aca-app')
// eslint-disable-next-line no-unused-vars
class AcaApp extends connect(store)(LitElement) {
    _provider = new ContextProvider(this, {context: gravatarUrl});

    constructor() {
        super();

        this.store = store;

        this._loadedModules = {};

        this._silentSignIn = true;
        this._signIn = false;
        this._signedIn = false;
        this._hasHeaders = false;

        this._router = new Router(this, [
            // {path: '/portaldoesnotexist', render: () => html`<aca-portal-does-not-exists-page></aca-portal-does-not-exists-page>`},
            // {path: '/unauthorized', render: () => html`<aca-unauthorized-page></aca-unauthorized-page>`},

            {
                path: '/callback',
                render: () => html`<authenticate-callback></authenticate-callback>`,
            },
            {
                path: '/silent_renew',
                render: () => html`<silent-renew-callback></silent-renew-callback>`,
            },
            {
                path: '/elearning-close-dialog',
                render: () => html`<sdb-elearning-close-dialog></sdb-elearning-close-dialog>`,
                enter: async () => {
                    await import(
                        '@competencegroup/tcg-composition-player/components/elearning/sdb-elearning-close-dialog.js'
                    );
                },
            },
            {
                path: '/close-preview',
                render: () => html`<sdb-preview-close-dialog></sdb-preview-close-dialog>`,
                enter: async () => {
                    await import(
                        '@competencegroup/tcg-composition-editor/components/preview/sdb-preview-close-dialog.js'
                    );
                },
            },
            {
                path: '/learningplan-close-dialog',
                render: () => html`<tcg-learningplan-dialog-close></tcg-learningplan-dialog-close>`,
                enter: async () => {
                    await import('./learningplan/tcg-learningplan-dialog-close.js');
                },
            },
            {
                path: '/go-to-uservoice',
                render: () => html`<sdb-uservoice></sdb-uservoice>`,
                enter: async () => {
                    await import('./sdb-uservoice.js');
                },
            },
            {
                path: '/scorm-player-close-dialog',
                render: () => html`<tcg-scorm-player-dialog-close></tcg-scorm-player-dialog-close>`,
                enter: async () => {
                    await import('./scorm/tcg-scorm-player-dialog-close.js');
                },
            },
            {
                path: '/costregistration-close-dialog',
                render: () =>
                    html`<tcg-costregistration-dialog-close></tcg-costregistration-dialog-close>`,
                enter: async () => {
                    await import('./costregistration/tcg-costregistration-dialog-close.js');
                },
            },
            {
                path: '/*',
                render: (params) => this._renderAcaMainPage(params),
                enter: async () => {
                    await import('./aca-main-page.js');
                    import(
                        '@competencegroup/tcg-composition-editor/components/preview/sdb-embedded-preview-dialog.js'
                    );
                    import(
                        '@competencegroup/tcg-composition-player/components/elearning/sdb-embedded-elearning-dialog.js'
                    );
                    import('./costregistration/tcg-costregistration-dialog.js');
                    import('./learningplan/tcg-learningplan-dialog.js');
                    import('./scorm/tcg-scorm-player-dialog.js');
                },
            },
        ]);

        this.addEventListener('route-to', this._routeTo);
        this.addEventListener('route-to-planning-schedule-list', this._routeToPlanningScheduleList);
        this.addEventListener('launch-elearning-item', this._launchElearningItem);

        // Make sure all custom styles are loaded before showing vaadin elements.
        // After showing styles cannot be registered.
        VaadinStyleLoader.registerAllStyles();
    }

    async _loadModule(path, importFn) {
        if (this._loadedModules[path]) return;

        await importFn();

        this._loadedModules[path] = true;
    }

    _routeTo(e) {
        const url = this._router.link(e.detail.route);
        const state = {};

        if (e.detail.prevRoute) {
            // if prevRoute is a (true) boolean use window.location.pathname otherwise use the value of prevRoute
            state.prevRoute =
                e.detail.prevRoute === true ? window.location.pathname : e.detail.prevRoute;
        } else if (e.detail.skillId) {
            // add id parameter to querystring when skillId is specified
            state.prevRoute = `${window.location.pathname}?id=${e.detail.skillId}`;
        }
        this.__routeTo(url, state, e.detail.skipHistory);
    }

    _routeToPlanningScheduleList(e) {
        const url = `/training/planning/${e.detail}/schedules/list`;
        const prevRoute = window.location.pathname;

        this.__routeTo(url, {prevRoute});
    }

    __routeTo(url, state = {}, skipHistory = false) {
        if (!skipHistory) {
            window.history.pushState(state, null, url);
        }
        this._router.goto(url);
        window.dispatchEvent(new CustomEvent('location-changed'));
    }

    _launchElearningItem(e) {
        this.elearningDialog.open(e.detail.item);
    }

    static get styles() {
        return [
            colors,
            css`
                :host {
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    flex-direction: column;

                    --iron-overlay-backdrop-opacity: 0.54;
                }
            `,
        ];
    }

    render() {
        return html`
            <tcg-signin-aware
                id="signinAware"
                scope="openid name api api_tcg_claims idString email"
                callback-uri="/"
                post-logout-redirect-uri="/"
            ></tcg-signin-aware>

            <app-location
                @route-changed=${async (e) => await this._router.goto(e.detail.value.path)}
            ></app-location>

            <store-provider .value="${this.store}">
                ${this._renderMainBody()}

                <tcg-costregistration-dialog></tcg-costregistration-dialog>
                <tcg-learningplan-dialog></tcg-learningplan-dialog>
                <tcg-scorm-player-dialog></tcg-scorm-player-dialog>
            </store-provider>

            ${this._user && this._settings.portalIdentifier
                ? html` <sdb-embedded-elearning-dialog
                          id="app_elearning_dialog"
                          .settings=${{
                              portalIdentifier: this._settings.portalIdentifier,
                              env: this._settings.compositionEnvironment,
                          }}
                          .user=${this._user}
                      >
                      </sdb-embedded-elearning-dialog>
                      <sdb-embedded-preview-dialog
                          id="app_preview_dialog"
                          .settings=${{
                              portalIdentifier: this._settings.portalIdentifier,
                              env: this._settings.compositionEnvironment,
                          }}
                          .user=${this._user}
                      >
                      </sdb-embedded-preview-dialog>`
                : nothing}

            <aca-app-update
                id="appUpdate"
                @app-update-requested=${() => {
                    this._appUpdateResolver();
                }}
            ></aca-app-update>
        `;
    }

    @property({type: Boolean, reflect: true})
    accessor sdb = false;

    @property({type: Boolean, reflect: true})
    accessor tcg = false;

    @state()
    accessor _settings;

    @state()
    accessor _user;

    @state()
    accessor _styleVars;

    @query('#app_elearning_dialog')
    accessor elearningDialog;

    static get properties() {
        return {
            _loading: {
                type: Boolean,
                observer: '_loadingChanged',
            },
            _timeout: {
                type: Number,
            },
        };
    }

    connectedCallback() {
        super.connectedCallback();

        store.dispatch({type: 'MINERVASETTINGS_REQUESTED'});

        this.useKeyIfMissing = true;
    }

    async fetchStyles(settingsUrl) {
        const response = await fetch(`${settingsUrl}/style`);

        this._styleVars = await response.json();
        this.applyStyles();
    }

    applyStyles() {
        for (const [key, value] of Object.entries(this._styleVars)) {
            this.style.setProperty(key, value);
        }
    }

    stateChanged(state) {
        super.stateChanged(state);

        if (state.minervaSettings.apiUrl) {
            this._provider.setValue(state.minervaSettings.apiUrl);
        }

        if (state.minervaSettings.redirects) {
            const current = window.location.hostname.toLowerCase();

            for (const hostFrom of Object.keys(state.minervaSettings.redirects)) {
                if (hostFrom.toLowerCase() === current) {
                    const hostTo = state.minervaSettings.redirects[hostFrom];

                    if (hostTo) {
                        window.location.href = `${window.location.protocol}//${hostTo}${window.location.pathname}${window.location.search}`;
                    }
                }
            }
        }

        // force sdb class, remainder of old/new UI. This sets an class="sdb" on this element, that's used in sharedStyles
        this.sdb = true;

        this._unAuthorized = state.minervaSettings.forceLogout === true;
        this._notFound = state.minervaSettings.notFound === true;

        if (this._unAuthorized) {
            this._loadModule(
                './aca-unauthorized-page.js',
                async () => await import('./aca-unauthorized-page.js'),
            );
            this._hideSplash();
        }

        if (this._notFound) {
            this._loadModule(
                './aca-portal-does-not-exists-page.js',
                async () => await import('./aca-portal-does-not-exists-page.js'),
            );
            this._hideSplash();
        }

        if (typeof state.minervaSettings.portalIdentifier !== 'undefined') {
            // check if we're done loading

            this._hideSplash();
        }

        this._settings = state.minervaSettings;

        if (!this._settingsUrl && this._settings && this._settings?.settingsUrl) {
            this._settingsUrl = this._settings.settingsUrl;
            this.fetchStyles(this._settingsUrl);
        }

        if (state.minervaSettings.customerTitle) {
            document.title = state.minervaSettings.customerTitle;
        }

        this._hasHeaders = !!state.minervaSettings?.headers?.Authorization;

        if (
            state.minervaSettings.loading === false &&
            state.oidc.user &&
            this.__prevAccessToken !== state.oidc.user.access_token
        ) {
            this.__prevAccessToken = state.oidc.user.access_token;
            // store.dispatch(fetchSettings(state.oidc.user));
            store.dispatch({type: 'MINERVASETTINGS_REQUESTED'});
        }

        if (state.oidc.user && state.oidc.user.access_token) {
            const signInAware = this.shadowRoot.querySelector('#signinAware');

            if (signInAware.token?.access_token !== state.oidc.user.access_token) {
                console.log('saving token');
                signInAware.saveToken(state.oidc.user);
            }
        }

        const isLoadingUser = !!state.oidc.isLoadingUser;

        if (this.isLoadingUser && !isLoadingUser) {
            if (!state.oidc.user) {
                // not signed in?
                // store.dispatch(navigate('/sign-in'));'
                this._silentSignIn = false;
                this._signIn = true;
                this._signedIn = false;
            } else {
                this._silentSignIn = false;
                this._signIn = false;
                this._signedIn = true;
            }
        }

        this.isLoadingUser = isLoadingUser;

        //  // Not SignedIn
        //  if (state.signIn.pending === false && state.signIn.signedIn === false) {
        //     // Show aca-signin-page
        //     this._silentSignIn = false;
        //     this._signIn = true;
        //     this._signedIn = false;
        // }

        // SignedIn
        if (state.signIn.pending === false && state.signIn.signedIn === true) {
            this._silentSignIn = false;
            this._signIn = false;
            this._signedIn = true;
        }

        this._user = state.oidc.user;
    }

    _renderMainBody() {
        // TODO: Convert to route
        if (this._notFound) {
            return html`<aca-portal-does-not-exists-page></aca-portal-does-not-exists-page>`;
        }

        // TODO: Convert to route
        if (this._unAuthorized) {
            return html`<aca-unauthorized-page></aca-unauthorized-page>`;
        }

        // TODO: Convert to route
        if (this._signIn) {
            return html`<aca-signin-page></aca-signin-page>`;
        }

        // TODO: Convert to route
        // if(this._silentSignIn) {
        //     return html`<aca-silent-signin-page></aca-silent-signin-page>`;
        // }

        // TODO: route to _notFound || _unAuthorized || _signIn || _silentSignIn from aca-main-page
        return html`${this._router.outlet()}`;
    }

    _renderAcaMainPage(params) {
        if (this._signedIn && this._hasHeaders) {
            return html`<aca-main-page></aca-main-page>`;
        }
    }

    promptForUpdate(updateAccepted) {
        this.shadowRoot.querySelector('#appUpdate').open();
        this._appUpdateResolver = updateAccepted;
    }

    _loadingChanged() {
        if (this._loading === undefined) return;
        if (this._loading) {
            window.clearTimeout(this._timeout);
            window.requestAnimationFrame(() => {
                document.querySelector('#splash').classList.remove('hide');
            });
        } else {
            this._hideSplash();
        }
    }

    _performHide() {
        window.requestAnimationFrame(() => {
            this._splash.classList.add('hide');

            window.setTimeout(() => {
                this._splash.parentNode?.removeChild(this._splash);
            }, 100);
        });
    }

    _hideSplash() {
        this._splash = document.querySelector('#splash');
        if (!this._splash) return;

        if (this._splash.classList.contains('hide')) return;

        window.clearTimeout(this._timeout);
        // const timeout = this._selected !== 0 ? 0 : 2000;
        const timeout = 100;

        if (!this._loading) {
            this._performHide();
        } else {
            this._timeout = window.setTimeout(() => {
                if (this._loading) {
                    return console.log('still loading. do not hide splash');
                } else {
                    this._performHide();
                }
            }, timeout);
        }
    }
}
