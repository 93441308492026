const app = (state = {}, action) => {
    switch (action.type) {
        case 'CATALOG_SETPARAMS': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }

        case 'CATALOG_LOADED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default app;
