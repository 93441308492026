import {GET_SCORM_RESULT, UPDATE_SCORM_RESULT} from '../actions/scorm-result';

const defaultState = {
    scormResult: undefined,
    canResume: false,
    isReview: false,
};

const app = (state = defaultState, action) => {
    const {type, payload} = action;

    switch (type) {
        // /
        // / GET_SCORM_RESULT
        // /
        case `${GET_SCORM_RESULT}_PENDING`: {
            return {
                ...defaultState,
            };
        }
        case `${GET_SCORM_RESULT}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }
        case `${GET_SCORM_RESULT}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / GET_SCORM_RESULT
        // /

        // /
        // / UPDATE_SCORM_RESULT
        // /
        case `${UPDATE_SCORM_RESULT}_PENDING`: {
            return {
                ...state,
            };
        }
        case `${UPDATE_SCORM_RESULT}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }
        case `${UPDATE_SCORM_RESULT}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / GET_SCORM_RESULT
        // /

        default:
            return state;
    }
};

export default app;
