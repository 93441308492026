/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import {sortOnDate} from '../utils/classroom.js';
import {
    ACCREDITATIONINFO_LOAD,
    ACCREDITATIONINFO_UPDATE,
    ACCREDITATIONINFO_CREATE,
    ACCREDITATIONINFO_REMOVE,
    ACCREDITATIONINFO_OPEN,
    ACCREDITATIONINFO_SAVE,
    ACCREDITATIONINFO_PROCESS,
} from '../actions/accreditationInfo.js';

const app = (state = {}, action) => {
    const {type, payload} = action;
    let index;
    let changes;
    let value;

    switch (type) {
        case `${ACCREDITATIONINFO_LOAD}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${ACCREDITATIONINFO_LOAD}_FULFILLED`: {
            const {accreditations, organizations, suppliers} = payload;

            return {
                ...state,
                loading: false,
                error: false,
                current: sortOnDate([...accreditations], 'validFrom', true),
                organizations: [...organizations],
                suppliers: [...suppliers],
                removedLegacyIds: [],
                dirty: false,
            };
        }
        case `${ACCREDITATIONINFO_LOAD}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: payload,
                current: [],
                organizations: [],
                suppliers: [],
            };
        case ACCREDITATIONINFO_UPDATE:
            ({index, changes} = payload);
            return {
                ...state,
                current: state.current.map((o, i) =>
                    i === index ? _deepExtend(o, {...changes, $dirty: true}) : o,
                ),
                dirty: true,
            };
        case ACCREDITATIONINFO_CREATE:
            return {
                ...state,
                current: [{...payload, $opened: true}, ...state.current],
                dirty: true,
            };
        case ACCREDITATIONINFO_REMOVE: {
            ({index} = payload);
            const {legacyId} = state.current[payload.index];

            return {
                ...state,
                current: state.current.filter((_, i) => i !== payload.index),
                removedLegacyIds: (state.removedLegacyIds || []).concat(legacyId || []),
                dirty: true,
            };
        }
        case ACCREDITATIONINFO_OPEN:
            ({value, index} = payload);

            return {
                ...state,
                current: state.current.map((o, i) => (i === index ? {...o, $opened: value} : o)),
            };
        case `${ACCREDITATIONINFO_SAVE}_PENDING`:
            return {
                ...state,
                save: 'pending',
                error: false,
            };
        case `${ACCREDITATIONINFO_SAVE}_FULFILLED`: {
            const {legacyIds} = payload;

            return {
                ...state,
                save: 'success',
                error: false,
                current: legacyIds
                    ? sortOnDate(
                          state.current.map(
                              (m, i) => ({
                                  ...m,
                                  $dirty: false,
                                  $opened: false,
                                  legacyId: legacyIds[i],
                              }),
                              'validFrom',
                              true,
                          ),
                      )
                    : sortOnDate(
                          state.current.map((m) => ({...m, $dirty: false, $opened: false})),
                          'validFrom',
                          true,
                      ),
                removedLegacyIds: [],
                dirty: false,
            };
        }
        case `${ACCREDITATIONINFO_SAVE}_REJECTED`:
            return {
                ...state,
                save: 'failed',
                error: payload,
            };
        case `${ACCREDITATIONINFO_PROCESS}_PENDING`:
            return {
                ...state,
                process: 'pending',
                error: false,
            };
        case `${ACCREDITATIONINFO_PROCESS}_FULFILLED`:
            return {
                ...state,
                process: 'success',
                error: false,
            };
        case `${ACCREDITATIONINFO_PROCESS}_REJECTED`:
            return {
                ...state,
                process: 'failed',
                error: payload,
            };
        default:
            return state;
    }
};

const _deepExtend = (target, ...changes) => {
    if (!changes.length) {
        return target;
    }
    const source = changes.shift();

    if (_isObject(target) && _isObject(source)) {
        for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = _isObject(source[key])
                    ? _deepExtend(target[key] || {}, source[key])
                    : (target[key] = source[key]);
            }
        }
    }
    return _deepExtend(target, ...changes);
};

const _isObject = (obj) => {
    return obj !== null && typeof obj === 'object';
};

export default app;
