import {call, put, select, takeLatest} from 'redux-saga/effects';
import {SKILLS_CATALOGITEMMAP_LOAD} from '../actions/skillsTargetIdMap';

const catalogItemsCache = {
    data: null,
    timestamp: 0,
};
const CACHE_VALIDITY_PERIOD = 1000 * 60 * 5; // 5 minutes in milliseconds

function* doCatalogItemsLoad(action) {
    const {minervaSettings} = yield select();

    if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
        return;
    }

    const now = new Date().getTime();

    // Check if cached data is still valid
    if (catalogItemsCache.data && now - catalogItemsCache.timestamp < CACHE_VALIDITY_PERIOD) {
        yield put({
            type: 'SKILLS_CATALOGITEMMAP_LOAD_DONE',
            payload: {catalogItems: catalogItemsCache.data},
        });
        return;
    }

    const response = yield call(
        fetch,
        `${minervaSettings.catalogApiUrl}/api/2.0/GetNamesAndTypes/${minervaSettings.portalIdentifier}`,
        {
            method: 'GET',
            headers: {
                ...minervaSettings.headers,
            },
        },
    );

    const catalogItems = yield call([response, response.json]);

    catalogItemsCache.data = catalogItems;
    catalogItemsCache.timestamp = new Date().getTime();

    yield put({
        type: 'SKILLS_CATALOGITEMMAP_LOAD_DONE',
        payload: {
            catalogItems: catalogItems,
        },
    });
}

export function* skillsTargetIdMapSaga() {
    yield takeLatest(SKILLS_CATALOGITEMMAP_LOAD, doCatalogItemsLoad);
}
