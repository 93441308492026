import {
    FETCH_SETTINGS,
    SAVE_SETTINGS,
    ADD_ADMIN,
    REMOVE_ADMIN,
} from '../actions/minervaSettings.js';

const convertEnabledFeatureFlags = (settings) => {
    // convert enabledFeatureFlags to object with keys as truthy booleans
    // Example: when feature with key 'uservoice' is enabled settings.enabledFeatureFlags.uservoice is true
    settings.enabledFeatureFlags = settings.enabledFeatureFlags.length
        ? Object.assign(...settings.enabledFeatureFlags.map((x) => ({[x]: true})))
        : {
              /* no features enabled */
          };
};

const app = (state = {}, action) => {
    switch (action.type) {
        case `${FETCH_SETTINGS}_PENDING`: {
            return {
                ...state,
                loading: true,
            };
        }
        case 'MINERVASETTINGS_NOT_FOUND': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case 'MINERVASETTINGS_RECEIVED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case 'SKILLS_SETTINGS_RECEIVED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case 'USER_DETAILS_RECEIVED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case `${FETCH_SETTINGS}_REJECTED`: {
            return {
                ...state,
                loading: false,
            };
        }
        case `${SAVE_SETTINGS}_PENDING`: {
            return {
                ...state,
                saved: false,
                loading: true,
            };
        }
        case `${SAVE_SETTINGS}_FULFILLED`: {
            const payload = action.payload;

            convertEnabledFeatureFlags(payload);

            return {
                ...state,
                ...payload,
                saved: true,
                loading: false,
            };
        }
        case `${SAVE_SETTINGS}_REJECTED`: {
            return {
                ...state,
                saved: false,
                loading: false,
            };
        }
        case `${ADD_ADMIN}_PENDING`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${ADD_ADMIN}_FULFILLED`: {
            return {
                ...state,
                saved: true,
            };
        }
        case `${ADD_ADMIN}_REJECTED`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${REMOVE_ADMIN}_PENDING`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${REMOVE_ADMIN}_FULFILLED`: {
            return {
                ...state,
                saved: true,
            };
        }
        case `${REMOVE_ADMIN}_REJECTED`: {
            return {
                ...state,
                saved: false,
            };
        }
        case 'SET_WELCOME_VIDEO_OPENED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        case 'DEPARTMENT_MATRIX_VISIBLE_SET': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default app;
