import {VALIDATE_ENROLLMENTHOOK} from '../actions/costregistration';
import {BUY} from '../actions/costregistration';
import {CUSTOM_BUY} from '../actions/costregistration';
import {CLOSE_DIALOG} from '../actions/costregistration';

const defaultState = {
    initialized: false,
    isLoading: false,
};

const app = (state = defaultState, action) => {
    const {type, payload} = action;

    switch (type) {
        // VALIDATE_ENROLLMENTHOOK
        case `${VALIDATE_ENROLLMENTHOOK}_PENDING`: {
            state.isLoading = true;

            return {
                ...state,
            };
        }
        case `${VALIDATE_ENROLLMENTHOOK}_FULFILLED`: {
            state.reload = false;
            state.initialized = true;
            state.isLoading = false;

            return {
                ...state,
                ...payload,
            };
        }
        case `${VALIDATE_ENROLLMENTHOOK}_REJECTED`: {
            state.isLoading = false;

            return {
                ...state,
            };
        }
        // VALIDATE_ENROLLMENTHOOK

        // BUY
        case `${BUY}_PENDING`: {
            state.isLoading = true;

            return {
                ...state,
            };
        }
        case `${BUY}_FULFILLED`: {
            state.isLoading = false;

            return {
                ...state,
                ...payload,
            };
        }
        case `${BUY}_REJECTED`: {
            state.isLoading = false;
            return {
                ...state,
            };
        }
        // BUY

        // CUSTOM_BUY
        case `${CUSTOM_BUY}_PENDING`: {
            state.isLoading = true;

            return {
                ...state,
            };
        }
        case `${CUSTOM_BUY}_FULFILLED`: {
            state.isLoading = false;

            return {
                ...state,
                ...payload,
            };
        }
        case `${CUSTOM_BUY}_REJECTED`: {
            state.isLoading = false;

            return {
                ...state,
            };
        }
        // BUY

        // CLOSE_DIALOG
        case `${CLOSE_DIALOG}_FULFILLED`: {
            return {
                ...defaultState,
                reload: true,
            };
        }
        // CLOSE_DIALOG

        default:
            return state;
    }
};

export default app;
