import {
    FETCH_STARTINFO,
    //    FETCH_MANIFESTINFO,
    SET_UNITINDEX,
    SET_LESSON,
    MANIFEST_COP_LOADED,
    SET_PAGE_PROGRESS,
    SET_PAGE_PROPERTIES,
    COMMIT_SCORM,
    FINISH_SCORM,
    CLOSE_DIGITAL_LEARNING,
    COMPLETE_DOCUMENT,
} from '../actions/digitallearning';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${FETCH_STARTINFO}_PENDING`:
            return {
                ...state,
                unitIndex: null,
                manifestUrl: null,
            };
        case `${FETCH_STARTINFO}_FULFILLED`:
            return {
                ...state,
                ...payload,
            };
        case `${FETCH_STARTINFO}_REJECTED`: {
            return {
                ...state,
            };
        }

        case `${COMPLETE_DOCUMENT}_PENDING`:
            return {
                ...state,
                unitIndex: null,
                manifestUrl: null,
            };
        case `${COMPLETE_DOCUMENT}_FULFILLED`:
            return {
                ...state,
                ...payload,
            };
        case `${COMPLETE_DOCUMENT}_REJECTED`: {
            return {
                ...state,
            };
        }

        case `${SET_UNITINDEX}_PENDING`:
            return {
                ...state,
            };

        case `${SET_UNITINDEX}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${SET_UNITINDEX}_REJECTED`: {
            return {
                ...state,
            };
        }

        case `${SET_LESSON}_PENDING`:
            return {
                ...state,
            };

        case `${SET_LESSON}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${SET_LESSON}_REJECTED`: {
            return {
                ...state,
            };
        }

        case `${SET_PAGE_PROGRESS}_PENDING`: {
            return {
                ...state,
            };
        }

        case `${SET_PAGE_PROGRESS}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${SET_PAGE_PROGRESS}_REJECTED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case SET_PAGE_PROPERTIES: {
            return {
                ...state,
                ...payload,
            };
        }

        case MANIFEST_COP_LOADED: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${COMMIT_SCORM}_PENDING`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${COMMIT_SCORM}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${COMMIT_SCORM}_REJECTED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${FINISH_SCORM}_PENDING`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${FINISH_SCORM}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${FINISH_SCORM}_REJECTED`: {
            return {
                ...state,
                ...payload,
            };
        }

        case `${CLOSE_DIGITAL_LEARNING}_FULFILLED`: {
            return {};
        }

        default:
            return state;
    }
};

export default app;
