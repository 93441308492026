/* eslint-disable operator-linebreak */
/* eslint-disable indent */
export const ACCREDITATIONINFO_LOAD = 'ACCREDITATIONINFO_LOAD';
export const ACCREDITATIONINFO_UPDATE = 'ACCREDITATIONINFO_UPDATE';
export const ACCREDITATIONINFO_CREATE = 'ACCREDITATIONINFO_CREATE';
export const ACCREDITATIONINFO_REMOVE = 'ACCREDITATIONINFO_REMOVE';
export const ACCREDITATIONINFO_OPEN = 'ACCREDITATIONINFO_OPEN';
export const ACCREDITATIONINFO_SAVE = 'ACCREDITATIONINFO_SAVE';
export const ACCREDITATIONINFO_PROCESS = 'ACCREDITATIONINFO_PROCESS';

export const loadAccreditationInfo = (catalogItem) => async (dispatch, getState) => {
    const {oidc, minervaSettings} = getState();
    const {portalIdentifier, catalogApiUrl, accreditationApiUrl} = minervaSettings;
    const {targetId, targetType} = catalogItem;

    dispatch({
        type: ACCREDITATIONINFO_LOAD,
        async payload() {
            let data;

            switch (targetType) {
                case 'composition':
                    data = await fetchJson(
                        `${catalogApiUrl}/api/1.0/accreditationinfo/${portalIdentifier}/${targetId}`,
                        oidc,
                    );

                    return {
                        accreditations: data.globalAccreditations
                            ? data.accreditations.concat(
                                  data.globalAccreditations.map((a) => {
                                      a.global = true;
                                      return a;
                                  }),
                              )
                            : data.accreditations,
                        suppliers: data.suppliers.map((s) => ({
                            value: s,
                            label: s,
                        })),
                        organizations: data.organizations.map((o) => ({
                            value: o.key,
                            label: o.key,
                            // displayName: o.name,
                        })),
                    };

                default: {
                    const accData = await fetchJson(
                        `${accreditationApiUrl}/api/2.0/course/${portalIdentifier}/${targetId}`,
                        oidc,
                    );
                    const supData = await fetchJson(
                        `${accreditationApiUrl}/api/2.0/allowedproviders/${portalIdentifier}`,
                        oidc,
                    );
                    const orgData = await fetchJson(
                        `${accreditationApiUrl}/api/2.0/accreditationtypes/${portalIdentifier}`,
                        oidc,
                    );

                    return {
                        accreditations: accData.courses.map(fromLegacyModel),
                        suppliers: supData.map((s) => ({
                            value: s.id.toString(),
                            label: s.providerName,
                        })),
                        organizations: orgData.map((s) => ({
                            value: s.value,
                            label: s.name,
                        })),
                    };
                }
            }
        },
    });
};

export const updateAccreditationMapping = (index, changes) => (dispatch) => {
    dispatch({
        type: ACCREDITATIONINFO_UPDATE,
        payload: {
            index: index,
            changes: changes,
        },
    });
};

export const createAccreditationMapping = () => (dispatch, getState) => {
    const state = getState();

    dispatch({
        type: ACCREDITATIONINFO_CREATE,
        payload: {
            supplierKey: state.accreditationInfo.suppliers[0].value,
            organizationKey: state.accreditationInfo.organizations[0].value,
            data: {},
        },
    });
};

export const removeAccreditationMapping = (index) => (dispatch) => {
    dispatch({
        type: ACCREDITATIONINFO_REMOVE,
        payload: {
            index: index,
        },
    });
};

export const openAccreditationMapping = (index, value) => (dispatch) => {
    dispatch({
        type: ACCREDITATIONINFO_OPEN,
        payload: {
            index: index,
            value: value,
        },
    });
};

export const saveAccreditationInfo = (catalogItem) => async (dispatch, getState) => {
    const {oidc, minervaSettings, accreditationInfo} = getState();
    const {portalIdentifier, catalogApiUrl, accreditationApiUrl} = minervaSettings;
    const {targetId, targetType, name} = catalogItem;

    dispatch({
        type: ACCREDITATIONINFO_SAVE,
        async payload() {
            switch (targetType) {
                case 'composition':
                    await fetch(
                        `${catalogApiUrl}/api/1.0/accreditationinfo/${portalIdentifier}/${targetId}`,
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                accreditationInfo.current.filter((a) => !a.global),
                            ),
                            headers: {
                                'Content-Type': 'application/json',
                                ...getAuthorizationHeaders(oidc.user),
                            },
                        },
                    );

                    return {};

                default: {
                    const legacyIds = [];
                    let res;

                    accreditationInfo.current
                        .filter((m) => m.$dirty)
                        .forEach(async (mapping) => {
                            res = await fetch(`${accreditationApiUrl}/api/2.0/course`, {
                                method: mapping.legacyId ? 'PUT' : 'POST',
                                body: JSON.stringify(
                                    toLegacyModel(
                                        mapping,
                                        portalIdentifier,
                                        targetId,
                                        targetType,
                                        name,
                                    ),
                                ),
                                headers: {
                                    'Content-Type': 'application/json',
                                    ...getAuthorizationHeaders(oidc.user),
                                },
                            });

                            const data = await res.json();

                            legacyIds.push(data.id);
                        });

                    accreditationInfo.removedLegacyIds.forEach(async (id) => {
                        res = await fetch(`${accreditationApiUrl}/api/2.0/course/${id}`, {
                            method: 'DELETE',
                            headers: getAuthorizationHeaders(oidc.user),
                        });
                        // do something with response
                    });

                    return {
                        legacyIds: legacyIds,
                    };
                }
            }
        },
    });
};

export const process = (catalogItem) => async (dispatch, getState) => {
    const {oidc, minervaSettings} = getState();
    const {portalIdentifier, catalogApiUrl} = minervaSettings;
    const {targetId, targetType} = catalogItem;

    if (targetType !== 'composition') {
        return;
    }

    dispatch({
        type: ACCREDITATIONINFO_PROCESS,
        async payload() {
            await fetch(
                `${catalogApiUrl}/api/1.0/accreditation/process/${portalIdentifier}/${targetId}`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        skipFailed: false,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        ...getAuthorizationHeaders(oidc.user),
                    },
                },
            );

            return {};
        },
    });
};

const fetchJson = async (url, oidc) => {
    const res = await fetch(url, {
        headers: getAuthorizationHeaders(oidc.user),
    });

    return await res.json();
};

const getAuthorizationHeaders = (user) => {
    return user ? {Authorization: `Bearer ${user.access_token}`} : {};
};

const fromLegacyModel = (model) => ({
    legacyId: model.id,
    organizationKey: model.accreditationType,
    supplierKey: model.providerId.toString(),
    validFrom: model.startDate,
    validTill: model.endDate,
    data: {
        peCourseId: model.courseId,
        peModuleId: model.moduleId,
        peEditionId: model.editionId,
    },
    points: model.points,
    contextId: model.academyContextId,
    contextInfo: model.contextInfo,
    global: model.global,
});

const toLegacyModel = (mapping, portalId, targetId, targetType, name) => ({
    id: mapping.legacyId,
    portalId: portalId,
    targetId: targetId,
    targetType: targetType === 'classroom' ? 2 : 1,
    accreditationType: mapping.organizationKey,
    providerId: +mapping.supplierKey,
    startDate: mapping.validFrom,
    endDate: mapping.validTill,
    courseName: name,
    courseId: mapping.data.peCourseId,
    moduleId: mapping.data.peModuleId,
    editionId: mapping.data.peEditionId,
    points: mapping.points,
    academyContextId: mapping.contextId,
    contextInfo: mapping.contextInfo,
    global: mapping.global,
});
