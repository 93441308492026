import {call, put, select, takeLatest, debounce} from 'redux-saga/effects';

const CACHE_RESULTS_IN_SECONDS = 300; /* 5 minutes */

function* getData() {
    const {minervaSettings} = yield select();

    if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
        return;
    }

    const url = new URL(
        `${minervaSettings.catalogApiUrl}/api/2.0/GetCatalogItemsForAssignment/${minervaSettings.portalIdentifier}`,
    );

    url.searchParams.set('includeDisabled', true);
    url.searchParams.set('excludePhasedOut', true);
    url.searchParams.set('excludeReplaced', true);
    url.searchParams.set('pageSize', 2147483647);

    const response = yield call(fetch, url, {
        headers: minervaSettings.headers,
    });

    return yield call([response, response.json]);
}

function* doAssignmentCatalogRequest() {
    const obj = yield call(getData);

    const results = obj.map((x) => ({
        name: x.displayName,
        targetId: x.id,
        disabled: x.disabled,
        onlyAssignableByAdmin: x.onlyAssignableByAdmin,
        item: x,
    }));

    const payload = {
        elearning: results.filter(
            (x) => x.item.targetType === 'learningplan' || x.item.targetType === 'composition',
        ),
        classical: results.filter((x) => x.item.targetType === 'classroom'),
    };

    yield put({type: 'ASSIGNMENT_CATALOG_SUCCESS', payload: payload});
}

function* doDebouncedAssignmentCatalogLoad() {
    const current = yield select((x) => x.assignmentCatalog);

    if (current.loading) {
        return console.log('request still in progress');
    }

    if (current.modified) {
        const seconds = Math.floor((Date.now() - current.modified) / 1000);

        if (seconds < CACHE_RESULTS_IN_SECONDS) {
            return console.log(
                `${seconds} seconds since last request, cache still valid for ${CACHE_RESULTS_IN_SECONDS - seconds} seconds.`,
            );
        }
    }

    yield put({type: 'ASSIGNMENT_CATALOG_REQUEST'});
}

export function* assignmentCatalogSaga() {
    yield debounce(500, 'ASSIGNMENT_CATALOG_LOAD', doDebouncedAssignmentCatalogLoad);
    yield takeLatest('ASSIGNMENT_CATALOG_REQUEST', doAssignmentCatalogRequest);
}
