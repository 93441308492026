import {signal} from './learningplan.js';
export const FETCH_STARTINFO = 'FETCH_STARTINFO';
export const SET_UNITINDEX = 'SET_UNITINDEX';
export const SET_LESSON = 'SET_LESSON';
export const MANIFEST_COP_LOADED = 'MANIFEST_COP_LOADED';
export const SET_PAGE_PROGRESS = 'SET_PAGE_PROGRESS';
export const SET_PAGE_PROPERTIES = 'SET_PAGE_PROPERTIES';
export const COMMIT_SCORM = 'COMMIT_SCORM';
export const FINISH_SCORM = 'FINISH_SCORM';
export const CLOSE_DIGITAL_LEARNING = 'CLOSE_DIGITAL_LEARNING';
export const COMPLETE_DOCUMENT = 'COMPLETE_DOCUMENT';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const fetchStartInfo =
    (itemId, itemDefinitionId, versionId) => async (dispatch, getState) => {
        const state = getState();

        const apiUrl = state.minervaSettings.portalApiUrl;

        if (!apiUrl) return;

        const {pageProgress, completesAtStart, requiresCompleteViewing} = state.digitalLearning;

        await dispatch({
            type: FETCH_STARTINFO,
            async payload() {
                const res = await fetch(
                    `${apiUrl}/api/1.0/digitalLearning/startInfo?sessionId=${itemId}&contentRootId=${itemDefinitionId}&version=${versionId}`,
                    {
                        headers: getHeaders(state.oidc.user),
                    },
                );

                const data = await res.json();

                const currentModule = data.state.currentModule;
                let lessonId = currentModule.children[0].id;
                let selectedLessonId = lessonId;
                let unitIndex = 0;

                if (data.state.selectedNavigation) {
                    const selectedUnitId = data.state.selectedNavigation.id;

                    const lesson = currentModule.children.find((l) =>
                        l.children.some((unit) => unit.id === selectedUnitId),
                    );

                    if (lesson) {
                        lessonId = lesson.id;

                        selectedLessonId = lessonId;
                        unitIndex = lesson.children.findIndex((unit) => unit.id === selectedUnitId);
                    }
                }

                const template = data.state.currentModule.template;

                if (template === 'scorm12') {
                    // TODO: multi-sco support
                    const currentUnit = getCurrentUnit(currentModule);

                    const lessonLocation = currentUnit.metaData.find(
                        (x) => x.name === 'ScormLocation',
                    );
                    const suspendData = currentUnit.metaData.find((x) => x.name === 'SuspendData');
                    const lessonStatus = currentUnit.metaData.find(
                        (x) => x.name === 'LessonStatus',
                    );
                    const entry = currentUnit.metaData.find((x) => x.name === 'Entry');
                    const launchData = currentUnit.metaData.find((x) => x.name === 'LaunchData');
                    const profile = getState().oidc.user.profile;

                    // strip http:// and https:// from the front, and '/' from the end (if it's there)
                    const rootUrl = apiUrl.replace(/(https?:\/\/)([^/]*)([/]?)/, '$2');

                    // change /scorm/123/some/path/to/file.txt to /scorm/develop.tcg-academy.nl/123/some/path/to/file.txt
                    const scormUrl = currentUnit.referenceKey.replace(
                        /(scorm)\/(.*)/,
                        `$1/${rootUrl}/$2`,
                    );

                    return {
                        ...data,
                        entry: entry ? entry.value : 'ab-initio',
                        lessonLocation: lessonLocation ? lessonLocation.value : null,
                        suspendData: suspendData ? suspendData.value : null,
                        launchData: launchData ? launchData.value : null,
                        lessonStatus: lessonStatus ? lessonStatus.displayValue : null, // Enum is returned as int
                        studentId: profile.idString,
                        studentName: profile.name,
                        copEnabled: false,
                        scormEnabled: true,
                        scormUrl,
                        currentModule,
                        itemId,
                        itemDefinitionId,
                        versionId,
                        template,
                        loadManifestCop: false,
                    };
                }

                if (template === 'scorm2004') {
                    throw new Error('Not supported');
                }

                // legacy reasons that this is mapped to each other. Fix it here
                const firstHidden = !currentModule.previousEnabled;
                const lastHidden = !currentModule.previousEnabled;

                const currentPageInUnit = 0;

                const pageData = getPageData(
                    currentModule,
                    selectedLessonId,
                    unitIndex,
                    currentPageInUnit,
                    pageProgress,
                    completesAtStart,
                    requiresCompleteViewing,
                );
                // TODO: handle isHistory

                let manifestUrl;

                switch (template) {
                    case 'document':
                        break;
                    default:
                        manifestUrl = await fetchManifestUrl(
                            state.oidc.user,
                            apiUrl,
                            itemId,
                            itemDefinitionId,
                            versionId,
                            pageData.selectedUnit.id,
                            false,
                        );
                        break;
                }
                return {
                    ...data,
                    ...pageData,
                    copEnabled: true,
                    scormEnabled: false,
                    currentModule,
                    itemId,
                    itemDefinitionId,
                    versionId,
                    template,
                    loadManifestCop: true,
                    manifestUrl,
                    firstHidden,
                    lastHidden,
                };
            },
        });
    };

const getCurrentUnit = (currentModule) => {
    let currentUnit = currentModule.children[0];

    // Find deeper scorm items. No multi sco support yet
    while (!currentUnit.referenceKey && currentUnit.children && currentUnit.children.length) {
        currentUnit = currentUnit.children[0];
    }

    return currentUnit;
};

export const queueCommitScorm = (data, finish, lmsFinish) => {
    return {
        queue: 'COMMIT_SCORM',
        callback: async (next, dispatch, getState) => {
            dispatch(commitScorm(finish, 'PENDING'));

            if (data) {
                await saveScorm(getState(), data);
            }

            if (finish) {
                await closeScorm(getState());
                if (lmsFinish) {
                    const workflowInstanceId = getState().workflow.workflowInstanceId;

                    await dispatch(signal(workflowInstanceId));

                    dispatch(closeDigitalLearning());
                }
            }

            dispatch(commitScorm(finish, 'FULFILLED'));
            next();
        },
    };
};

// https://gist.github.com/jed/982883
const newguid = (a) => {
    return a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, newguid);
};

const commitScorm = (finish, state) => {
    if (finish) {
        return {
            // HACK: crossing over to workflow state.
            type: `CLOSE_COP_${state}`,
            payload: {
                itemDefinitionId: null,
            },
        };
    }
    return {
        type: `${COMMIT_SCORM}_${state}`,
    };
};

const saveScorm = async (state, data) => {
    const apiUrl = state.minervaSettings.portalApiUrl;

    const {currentModule, rootId, sessionId, startDateTime} = state.digitalLearning;

    if (!currentModule) return;

    // TODO: multi-sco

    const currentUnit = getCurrentUnit(currentModule);

    await fetch(`${apiUrl}/api/scorm12/result`, {
        headers: getHeaders(state.oidc.user),
        method: 'POST',
        body: JSON.stringify({
            cmiElements: data.map((el) => {
                return {name: el.name, value: el.value};
            }),
            params: [],
            resultSessionId: newguid(),
            objectId: currentUnit.id,
            contentId: currentUnit.referenceId,
            version: currentUnit.version,
            startDateTime: startDateTime,
            moduleId: currentModule.id,
            sessionRootId: rootId,
            sessionId: sessionId,
        }),
    });
};

const closeScorm = async (state) => {
    const apiUrl = state.minervaSettings.portalApiUrl;
    const {currentModule, itemId, itemDefinitionId, versionId, startDateTime} =
        state.digitalLearning;

    if (!currentModule) {
        // Already closed
        return;
    }

    const currentUnit = getCurrentUnit(currentModule);

    await fetch(`${apiUrl}/api/1.0/digitalLearning/closeSession`, {
        method: 'POST',
        headers: getHeaders(state.oidc.user),
        body: JSON.stringify({
            sessionId: itemId,
            contentRootId: itemDefinitionId,
            version: versionId,
            objectId: currentUnit.id,
            contentId: currentUnit.referenceId,
            contentVersion: currentUnit.version,
            startDateTime: startDateTime,
        }),
    });
};

const getPageData = (
    currentModule,
    selectedLessonId,
    unitIndex,
    currentPageInUnit,
    pageProgress,
    completesAtStart,
    requiresCompleteViewing,
    hasResponded,
) => {
    const selectedLesson = currentModule.children.find((x) => x.id === selectedLessonId);
    const selectedUnit = selectedLesson.children[unitIndex];
    const currentPageInLesson = _getCurrentPage(
        selectedLesson.children,
        unitIndex,
        currentPageInUnit,
    );
    const pageCount = selectedLesson.children.reduce((sum, value) => sum + value.pageCount, 0);

    const firstDisabled = _firstDisabled(currentModule, unitIndex, currentPageInUnit);
    const previousDisabled = _previousDisabled(currentModule, unitIndex, currentPageInUnit);

    const nextDisabled = _nextDisabled(
        currentPageInLesson,
        pageCount,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
        hasResponded,
    );
    const lastDisabled = nextDisabled || currentPageInLesson >= pageCount;

    return {
        unitIndex,
        currentPageInUnit,
        selectedLesson,
        selectedUnit,
        pageCount,
        currentPageInLesson,
        firstDisabled,
        previousDisabled,
        nextDisabled,
        lastDisabled,
    };
};

const _firstDisabled = (module, selectedUnitId, _currentPage) => {
    if (!module.previousEnabled) return true;

    if (selectedUnitId > 0) return false;

    if (_currentPage > 0) return false;

    return true;
};

const _previousDisabled = (module, selectedUnitId, _currentPage) => {
    if (!module.previousEnabled) return true;

    if (selectedUnitId > 0) return false;

    if (_currentPage > 0) return false;

    return true;
};

const _nextDisabled = (
    currentPageInLesson,
    pageCount,
    pageProgress,
    completesAtStart,
    requiresCompleteViewing,
    hasResponded,
) => {
    const isLastPage = currentPageInLesson + 1 >= pageCount;

    if (isLastPage) return true;

    // Was commented already:
    // if(alreadyResponded) return false;

    if (hasResponded) return false;
    // if (progressCompleted) return false;

    if (completesAtStart && !requiresCompleteViewing) return false;

    return true;
};

const fetchManifestUrl = async (
    user,
    apiUrl,
    itemId,
    itemDefinitionId,
    versionId,
    unitId,
    isHistory,
) => {
    const uri = new URL(`${apiUrl}/api/1.0/digitalLearning/manifestInfo`);

    uri.searchParams.set('sessionId', itemId);
    uri.searchParams.set('contentRootId', itemDefinitionId);
    uri.searchParams.set('version', versionId);
    uri.searchParams.set('unitId', unitId);
    uri.searchParams.set('hm', isHistory);
    const manifestRes = await fetch(uri.toString(), {
        headers: getHeaders(user),
    });

    const manifestData = await manifestRes.json();

    return manifestData.url;
};

const _getCurrentPage = (units, unitIndex, currentPage) => {
    if (!units) return 0;

    let pageCount = 0;

    for (let i = 0; i < unitIndex && i < units.length; i++) {
        const value = units[i];

        pageCount += value.pageCount;
    }

    return pageCount + currentPage;
};

export const manifestCopLoaded = () => (dispatch, getstate) => {
    dispatch({
        type: MANIFEST_COP_LOADED,
        payload: {
            loadManifestCop: false,
        },
    });
};

export const completeDocument = (id, referenceId, contentVersion) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const {itemId, itemDefinitionId, versionId} = getState().digitalLearning;

    await dispatch({
        type: COMPLETE_DOCUMENT,
        async payload() {
            const result = await fetch(`${apiUrl}/api/1.0/digitalLearning/completeDocument`, {
                headers: getHeaders(user),
                method: 'POST',
                body: JSON.stringify({
                    // document properties
                    id: id,
                    referenceId: referenceId,
                    contentVersion: contentVersion,
                    // session / module properties
                    sessionId: itemId,
                    contentRootId: itemDefinitionId,
                    version: versionId,
                }),
            });

            const data = await result.json();

            return data;
        },
    });
};

export const setLesson = (newSelectedLessonId) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const {currentModule, itemId, itemDefinitionId, versionId, selectedLessonId, template} =
        getState().digitalLearning;

    if (selectedLessonId === newSelectedLessonId) return;

    const selectedLesson = currentModule.children.find((l) => l.id === newSelectedLessonId);
    const newUnitIndex = 0;
    const newSelectedUnit = selectedLesson.children[newUnitIndex];

    // TODO: handle isHistory
    await dispatch({
        type: SET_LESSON,
        async payload() {
            // TODO: handle isHistory
            let manifestUrl;

            switch (template) {
                case 'document':
                    break;
                default:
                    manifestUrl = await fetchManifestUrl(
                        user,
                        apiUrl,
                        itemId,
                        itemDefinitionId,
                        versionId,
                        newSelectedUnit.id,
                        false,
                    );

                    // save navigation
                    await fetch(`${apiUrl}/api/1.0/digitalLearning/saveSelectedNavigation`, {
                        headers: getHeaders(user),
                        method: 'POST',
                        body: JSON.stringify({
                            sessionId: itemId,
                            contentRootId: itemDefinitionId,
                            version: versionId,
                            objectId: newSelectedUnit.id,
                        }),
                    });

                    break;
            }

            return {
                unitIndex: newUnitIndex,
                selectedUnit: newSelectedUnit,
                selectedLesson,
                manifestUrl,
                loadManifestCop: true,
            };
        },
    });
};

export const setUnitIndex = (newUnitIndex) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const {selectedLesson, itemId, itemDefinitionId, versionId} = getState().digitalLearning;

    if (newUnitIndex === null) {
        return;
        // newUnitIndex = unitIndex;
    }

    const newSelectedUnit = selectedLesson.children[newUnitIndex];

    await dispatch({
        type: SET_UNITINDEX,
        async payload() {
            // TODO: handle isHistory
            const manifestUrl = await fetchManifestUrl(
                user,
                apiUrl,
                itemId,
                itemDefinitionId,
                versionId,
                newSelectedUnit.id,
                false,
            );

            // save navigation
            const saveNavigationResponse = await fetch(
                `${apiUrl}/api/1.0/digitalLearning/saveSelectedNavigation`,
                {
                    headers: getHeaders(user),
                    method: 'POST',
                    body: JSON.stringify({
                        sessionId: itemId,
                        contentRootId: itemDefinitionId,
                        version: versionId,
                        objectId: newSelectedUnit.id,
                    }),
                },
            );

            return {
                unitIndex: newUnitIndex,
                selectedUnit: newSelectedUnit,
                manifestUrl,
                loadManifestCop: true,
            };
        },
    });
};

export const queueSetPageProgress = (
    currentPageInUnit,
    pageProgress,
    copState,
    result,
    completesAtStart,
    requiresCompleteViewing,
    hasResponded,
) => {
    return {
        queue: 'SET_PAGE_PROGRESS',
        callback: async (next, dispatch, getState) => {
            console.log('starting page progress');
            const user = getState().oidc.user;
            const apiUrl = getState().minervaSettings.portalApiUrl;
            const {
                currentModule,
                selectedLesson,
                unitIndex,
                selectedUnit,
                itemId,
                itemDefinitionId,
                versionId,
                startDateTime,
            } = getState().digitalLearning;

            dispatch({type: `${SET_PAGE_PROGRESS}_PENDING`});

            await fetch(`${apiUrl}/api/1.0/digitalLearning/saveSession`, {
                headers: getHeaders(user),
                method: 'POST',
                body: JSON.stringify({
                    sessionId: itemId,
                    contentRootId: itemDefinitionId,
                    version: versionId,
                    objectId: selectedUnit.id,
                    contentId: selectedUnit.referenceId,
                    contentVersion: selectedUnit.version,
                    startDateTime: startDateTime,

                    hasResponded: hasResponded || false,
                    result: result,
                    state: copState,
                }),
            });

            const pageData = getPageData(
                currentModule,
                selectedLesson.id,
                unitIndex,
                currentPageInUnit,
                pageProgress,
                completesAtStart,
                requiresCompleteViewing,
                hasResponded,
            );

            dispatch({
                type: `${SET_PAGE_PROGRESS}_FULFILLED`,
                payload: {
                    ...pageData,
                    pageProgress,
                    copState,
                    result,
                    hasResponded: hasResponded || false,
                },
            });
            next();
        },
    };
};

export const setPageProperties =
    (currentPageInUnit, completesAtStart, requiresCompleteViewing) => (dispatch, getState) => {
        const {currentModule, selectedLesson, unitIndex, pageProgress} = getState().digitalLearning;

        const pageData = getPageData(
            currentModule,
            selectedLesson.id,
            unitIndex,
            currentPageInUnit,
            pageProgress,
            completesAtStart,
            requiresCompleteViewing,
        );

        dispatch({
            type: SET_PAGE_PROPERTIES,
            payload: {
                ...pageData,
            },
        });
    };

export const copPageSet = () => (dispatch) => {
    dispatch({
        type: SET_PAGE_PROPERTIES,
        payload: {
            setCopPage: false,
        },
    });
};

export const gotoFirstPage = () => async (dispatch, getState) => {
    const {currentModule, selectedLesson, pageProgress, completesAtStart, requiresCompleteViewing} =
        getState().digitalLearning;

    const unitIndex = 0;
    const currentPageInUnit = 0;

    await dispatch(setUnitIndex(unitIndex));
    const pageData = getPageData(
        currentModule,
        selectedLesson.id,
        unitIndex,
        currentPageInUnit,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    );

    dispatch({
        type: SET_PAGE_PROPERTIES,
        payload: {
            ...pageData,
            setCopPage: true,
        },
    });
};

export const gotoPreviousPage = () => async (dispatch, getState) => {
    let {
        currentModule,
        selectedLesson,
        currentPageInUnit,
        unitIndex,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    } = getState().digitalLearning;

    if (currentPageInUnit > 0) {
        currentPageInUnit--;
    } else {
        unitIndex--;
        const selectedUnit = selectedLesson.children[unitIndex];

        currentPageInUnit = selectedUnit.pageCount - 1;

        await dispatch(setUnitIndex(unitIndex));
    }

    const pageData = getPageData(
        currentModule,
        selectedLesson.id,
        unitIndex,
        currentPageInUnit,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    );

    dispatch({
        type: SET_PAGE_PROPERTIES,
        payload: {
            ...pageData,
            setCopPage: true,
        },
    });
};

export const gotoNextPage = () => async (dispatch, getState) => {
    let {
        currentModule,
        selectedLesson,
        currentPageInUnit,
        unitIndex,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    } = getState().digitalLearning;

    const selectedUnit = selectedLesson.children[unitIndex];

    if (currentPageInUnit + 1 >= selectedUnit.pageCount) {
        unitIndex++;
        currentPageInUnit = 0;
        await dispatch(setUnitIndex(unitIndex));
    } else {
        currentPageInUnit++;
    }

    const pageData = getPageData(
        currentModule,
        selectedLesson.id,
        unitIndex,
        currentPageInUnit,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    );

    dispatch({
        type: SET_PAGE_PROPERTIES,
        payload: {
            ...pageData,
            setCopPage: true,
        },
    });
};

export const gotoLastPage = () => (dispatch, getState) => {
    const {
        currentModule,
        selectedLesson,
        unitIndex,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    } = getState().digitalLearning;

    const selectedUnit = this.units[unitIndex];
    const currentPageInUnit = selectedUnit.pageCount - 1;

    const pageData = getPageData(
        currentModule,
        selectedLesson.id,
        unitIndex,
        currentPageInUnit,
        pageProgress,
        completesAtStart,
        requiresCompleteViewing,
    );

    dispatch({
        type: SET_PAGE_PROPERTIES,
        payload: {
            ...pageData,
            setCopPage: true,
        },
    });
};

const closeDigitalLearning = () => (dispatch, getState) => {
    dispatch({
        type: CLOSE_DIGITAL_LEARNING,
        async payload() {
            await Promise.resolve(1);
            return {};
        },
    });
};
