import {html, css, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {tcgLocalizeBehaviorMixin} from './localize/tcg-localize-behavior.js';
import sharedStyles from './styles/tcg-shared-styles.js';
import {store} from './store.js';
import {connect} from 'pwa-helpers/connect-mixin.js';
import './aca-old-browser.js';
import '@sdblearning/sdb-learning-frontend';
import './aca-signin-auto-redirect';

@customElement('aca-signin-page')
class AcaSigninPage extends connect(store)(tcgLocalizeBehaviorMixin(LitElement)) {
    static styles = [
        sharedStyles,
        css`
            :host {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-image: url('/images/login-background.png');
                background-size: cover;
            }

            .signin-container {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .logo {
                max-width: 568px;
                width: 100%;
            }

            .text {
                margin: 0 20px;
            }

            .title {
                padding: 16px 0;
                font-size: 24px;
                font-weight: 400;
            }

            .card-actions {
                margin-top: 15px;
            }

            #welcomeText {
                white-space: pre-wrap;
            }

            @media (max-width: 600px) {
                .signin-container {
                    display: block;
                    text-align: center;
                }
            }

            sdb-content-card {
                border: none;
                box-sizing: border-box;
                position: relative;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                max-width: 80%;
                background-color: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(2px);
            }
        `,
    ];

    @state() accessor _styleVars;

    render() {
        return html`
            <aca-old-browser></aca-old-browser>

            ${this._skipPreLogonWelcomeWelcomeScreen
                ? html` <aca-signin-auto-redirect></aca-signin-auto-redirect> `
                : html`
                      <sdb-content-card>
                          <div class="signin-container">
                              <div>
                                  <img
                                      class="logo"
                                      src="${this._welcomeLogoUrl}"
                                  />
                              </div>

                              <div class="text">
                                  <div class="title">${this._welcomeTitle}</div>
                                  <div id="welcomeText">${this._welcomeText}</div>
                              </div>
                              <div class="card-actions">
                                  ${this._accountCreationEnabled
                                      ? html`
                                            <sdb-content-button
                                                id="btnRegister"
                                                @click=${(e) => this.__register(e)}
                                                .label=${this.localize('register')}
                                            ></sdb-content-button>
                                        `
                                      : html``}
                                  <sdb-content-button
                                      id="btnNext"
                                      @click=${(e) => this.__signIn(e)}
                                      .label=${this.localize('next')}
                                  ></sdb-content-button>
                              </div>
                          </div>
                      </sdb-content-card>
                  `}
        `;
    }

    static get properties() {
        return {
            _settings: {
                type: Object,
            },
            _paperButtonRaised: {
                type: Boolean,
                value: false,
            },
        };
    }

    static get observers() {
        return ['_resourcesChanged(resources.*)'];
    }

    stateChanged(state) {
        this._skipPreLogonWelcomeWelcomeScreen =
            state.minervaSettings.skipPreLogonWelcomeWelcomeScreen || false;
        this._welcomeLogoUrl =
            state.minervaSettings.preLogonWelcomeLogoUrl ||
            state.minervaSettings.customerLogoUrl ||
            '/images/sdb-login.png';
        this._welcomeTitle = state.minervaSettings.preLogonWelcomeTitle || 'SDB Leerplatform';
        this._welcomeText =
            state.minervaSettings.preLogonWelcomeText || this.localize('signInToUse');
        this._accountCreationEnabled = state.minervaSettings.accountCreationEnabled;
        this._accountCreationUrl = state.minervaSettings.accountCreationUrl;
        this._settings = state.minervaSettings;
    }

    connectedCallback() {
        super.connectedCallback();

        this.useKeyIfMissing = true;
    }

    _resourcesChanged() {
        if (!this._welcomeText) {
            this._welcomeText = this.localize('signInToUse');
        }
    }

    __signIn() {
        store.dispatch({type: 'SIGNIN'});
    }

    __register() {
        window.open(this._accountCreationUrl, '_blank');
    }
}
