const _getTrackingRecord = (trackingRecords, itemId) => {
    return trackingRecords.find((x) => x.itemDefinitionId === itemId);
};

const selfVisible = (node, trackingRecord) => {
    if (!node) return false;

    if (!node.visibility) return false;
    switch (node.visibility.toLowerCase()) {
        case 'visible':
            return true;
        case 'hidden':
            return false;
        case 'active':
        case '':
            return trackingRecord && trackingRecord.state.toLowerCase() !== 'dontcare';
        case 'children':
            return false;
    }

    return false;
};

const childrenVisible = (node, trackingRecord) => {
    if (!node) return false;

    if (node.type === 'choose' || node.type === 'option' || node.type === 'otherwise') return true;

    if (!node.visibility) {
        return trackingRecord && trackingRecord.state.toLowerCase() !== 'dontcare';
    }

    if (selfVisible(node, trackingRecord)) {
        return true;
    }

    switch (node.visibility.toLowerCase()) {
        case 'children':
        case 'hidden':
            return trackingRecord && trackingRecord.state.toLowerCase() !== 'dontcare';
        case 'active':
            return false;
        default:
            return true;
    }
};

const isCompleted = (node, trackingRecord) => {
    if (!node) return false;

    if (!trackingRecord || !trackingRecord.state) {
        return false;
    }

    switch (trackingRecord.state.toLowerCase()) {
        case 'completed':
            return true;
    }
    return false;
};

function getStartDisabled(node, trackingRecord) {
    if (!node.type) return false;

    switch (node.type.toLowerCase()) {
        case 'externalelearning':
        case 'elearningmodule':
            if (!trackingRecord) return true;
            return false;
        case 'elearning':
        case 'history':
        case 'classroomenroll':
        case 'hyperlink':
        case 'form':
        case 'subworkflow':
            if (!trackingRecord) return true;

            switch (trackingRecord.state.toLowerCase()) {
                case 'completed':
                    return node.history !== 'true';
                default:
                    return false;
            }
        default:
            return false;
    }
}

function getSkipLinkVisible(node, trackingRecord, isTester) {
    if (!node.type) return false;

    if (!trackingRecord) return false;

    if (trackingRecord.state.toLowerCase() === 'started') {
        if (node.abandonable === 'true') {
            return true;
        } else {
            switch (node.type.toLowerCase()) {
                case 'elearning':
                case 'form':
                case 'subworkflow':
                    return isTester;
                default:
                    return false;
            }
        }
    }

    return false;
}

function getSkipLinkText(node, trackingRecord, isTester) {
    if (!trackingRecord) return '';

    if (trackingRecord.state.toLowerCase() !== 'started') return '';

    if (node.abandonable === 'true') return 'tcgLearningPlan.handIn';

    switch (node.type.toLowerCase()) {
        case 'elearning':
        case 'elearningmodule':
        case 'form':
        case 'subworkflow':
            if (isTester) return 'tcgLearningPlan.skip';
            return '';
        default:
            return '';
    }
}

function getSkipLinkIcon(skipLinkText) {
    switch (skipLinkText) {
        case 'tcgLearningPlan.handIn':
            return 'fal fa-paper-plane-top';
        case 'tcgLearningPlan.skip':
            return 'fal fa-forward-step';
        default:
            return '';
    }
}

export const setVisibility = (sequence, trackingRecords, isTester) => {
    sequence.trackingRecord = _getTrackingRecord(trackingRecords, sequence.id);

    sequence.selfVisible = selfVisible(sequence, sequence.trackingRecord);
    sequence.childrenVisible = childrenVisible(sequence, sequence.trackingRecord);
    sequence.isCompleted = isCompleted(sequence, sequence.trackingRecord);
    sequence.startDisabled = getStartDisabled(sequence, sequence.trackingRecord);

    sequence.skipLinkVisible = getSkipLinkVisible(sequence, sequence.trackingRecord, isTester);

    if (sequence.skipLinkVisible) {
        sequence.skipLinkText = getSkipLinkText(sequence, sequence.trackingRecord, isTester);
        sequence.skipLinkIcon = getSkipLinkIcon(sequence.skipLinkText);
    }

    if (sequence.items) {
        sequence.items.forEach((i) => {
            setVisibility(i, trackingRecords, isTester);
        });
    }
};
