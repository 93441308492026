import {call, debounce, put, putResolve, select, takeEvery, takeLatest} from 'redux-saga/effects';

function* getData(pageNumber) {
    pageNumber = pageNumber || 0;

    const {minervaSettings, adminCatalog} = yield select();

    if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
        return;
    }

    // console.log(`load admin catalog page: ${pageNumber}`);

    const url = new URL(
        `${minervaSettings.catalogApiUrl}/api/2.0/admin-catalog/${minervaSettings.portalIdentifier}`,
    );

    url.searchParams.set('pageSize', adminCatalog.pageSize);
    url.searchParams.set('pageNumber', pageNumber);

    if (adminCatalog.searchString) {
        url.searchParams.set('searchString', adminCatalog.searchString);
    }

    // url.searchParams.set('displayOldItems', !!adminCatalog.displayOldItems);
    // url.searchParams.set('displayFutureItems', !!adminCatalog.displayFutureItems);

    const response = yield call(fetch, url, {
        headers: minervaSettings.headers,
    });

    return yield call([response, response.json]);
}

/**
 * Resets all data and loads the first page using the default (or stored) filters
 * @param {object} action The action
 */
function* doCatalogReset(action) {
    yield call(doSetSearch, {
        payload: {
            loadingEnabled: true,
            pageNumber: 0,
            pageSize: 25,
            results: [],
            searchString: '',
            ...action.payload,
        },
    });
}

/**
 * Loads the (next) page from the paging API
 */
function* doCatalogLoad() {
    const adminCatalog = yield select((x) => x.adminCatalog);

    // When pageNumber > 0 we need to check if loading is needed..
    if (adminCatalog.pageNumber > 0) {
        // When pageSize >= the totalResults returned there must be a filter active so do not fetch more data
        if (adminCatalog.pageSize >= adminCatalog.totalResults) return;
        // Only call getData when we know there's more data
        if (!adminCatalog.totalResults || adminCatalog.results.length >= adminCatalog.totalResults)
            return;
    }

    const obj = yield call(getData, adminCatalog.pageNumber);

    const results = [...adminCatalog.results, ...obj.results];

    yield put({
        type: 'ADMIN_CATALOG_LOADED',
        payload: {
            ...adminCatalog,
            ...obj,
            results: results,
            totalResults: obj.totalResults,
            pageNumber: adminCatalog.pageNumber + 1,
            loading: false,
        },
    });
}

function* doSetSearch(action) {
    const adminCatalog = yield select((x) => x.adminCatalog);

    if (!adminCatalog) return;

    const defaults = {
        pageNumber: 0,
        pageSize: 25,
        results: [],
        searchString: '',
    };

    const newCatalog = {
        ...defaults,
        ...adminCatalog,
        ...action.payload,
    };

    // avoid circular references in JSON stringify
    const skipParents = (key, value) => {
        if (key === 'parent') return;
        return value;
    };

    // Check if we actually have changes
    if (JSON.stringify(adminCatalog, skipParents) === JSON.stringify(newCatalog, skipParents)) {
        return;
    }

    yield putResolve({
        type: 'ADMIN_CATALOG_SETPARAMS',
        payload: {
            ...newCatalog,
            pageNumber: 0,
            results: [],
            loading: true,
        },
    });

    yield put({type: 'ADMIN_CATALOG_PARAMSSET'});
}

function* doDebouncedCatalogLoad() {
    yield put({type: 'ADMIN_CATALOG_LOAD'});
}

export function* adminCatalogSaga() {
    yield takeEvery('ADMIN_CATALOG_SETSEARCH', doSetSearch);
    yield takeLatest('ADMIN_CATALOG_RESET', doCatalogReset);
    yield takeLatest('ADMIN_CATALOG_LOAD', doCatalogLoad);
    yield debounce(1000, 'ADMIN_CATALOG_PARAMSSET', doDebouncedCatalogLoad);
}
