import {
    HIDE_NOT_ASSIGNED_TRAININGS_TOGGLE_FILTER,
    SELECT_USER_FUNCTION_FOR_TRAININGS_FILTER,
} from '../actions/trainingsSettings.js';

const app = (state = {}, action) => {
    switch (action.type) {
        case HIDE_NOT_ASSIGNED_TRAININGS_TOGGLE_FILTER: {
            return {
                ...state,
                hideNotAssignedTrainings: action.payload,
            };
        }
        case SELECT_USER_FUNCTION_FOR_TRAININGS_FILTER: {
            return {
                ...state,
                functionIdForTrainings: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default app;
