import {
    INIT_API,
    START_SCORM,
    COMMIT_SCORM,

    // GET_SCORM_RESULT,
    // UPDATE_SCORM_RESULT,
    CLOSE_DIALOG,
} from '../actions/scorm';

const defaultState = {};

const app = (state = defaultState, action) => {
    const {type, payload} = action;

    switch (type) {
        // /
        // / INIT_API
        // /
        case `${INIT_API}_PENDING`: {
            return {
                ...state,
            };
        }
        case `${INIT_API}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }
        case `${INIT_API}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / INIT_API
        // /

        // /
        // / START_SCORM
        // /
        case `${START_SCORM}_PENDING`: {
            return {
                ...state,
            };
        }
        case `${START_SCORM}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }
        case `${START_SCORM}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / START_SCORM
        // /

        // /
        // / COMMIT_SCORM
        // /
        case `${COMMIT_SCORM}_PENDING`: {
            return {
                ...state,
            };
        }
        case `${COMMIT_SCORM}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }
        case `${COMMIT_SCORM}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / COMMIT_SCORM
        // /

        // /
        // / CLOSE_DIALOG
        // /
        case `${CLOSE_DIALOG}_PENDING`: {
            return {
                ...state,
            };
        }
        case `${CLOSE_DIALOG}_FULFILLED`: {
            // Remove ScormApi from the window
            window.API = null;

            return {
                ...defaultState,
            };
        }
        case `${CLOSE_DIALOG}_REJECTED`: {
            return {
                ...state,
            };
        }
        // /
        // / CLOSE_DIALOG
        // /

        default:
            return state;
    }
};

export default app;
