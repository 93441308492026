export function hasRight(rights, ...args) {
    if (rights.find((r) => r === '*')) return true; // user has admin rights
    for (let i = 0; i < args.length; i++) {
        const right = args[i];

        if (rights.find((r) => r === right)) return true; // user has this specific right
        if (rights.find((r) => r.startsWith(`${right}.`))) return true; // user has any right for specified part
    }
    return false;
}
