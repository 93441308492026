import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {mixinBehaviors} from '@polymer/polymer/lib/legacy/class.js';
import {TcgLocalizeBehavior} from './localize/tcg-localize-behavior.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-button/paper-button.js';
import './styles/tcg-shared-styles.js';

class AcaAppUpdate extends mixinBehaviors([TcgLocalizeBehavior], PolymerElement) {
    static get is() {
        return 'aca-app-update';
    }

    static get template() {
        return html`
            <style include="tcg-shared-styles">
                #reload {
                    color: var(--sdb-primary-button, red);
                }
            </style>

            <paper-toast
                duration="0"
                id="updateToast"
                text="[[localize('newVersion')]]"
            >
                <paper-button
                    id="reload"
                    on-tap="_reload"
                    >[[localize('refresh')]]</paper-button
                >
            </paper-toast>
        `;
    }

    _reload() {
        this.dispatchEvent(new CustomEvent('app-update-requested'));
    }

    open() {
        this.$.updateToast.open();
    }
}
window.customElements.define(AcaAppUpdate.is, AcaAppUpdate);
