/* eslint-disable indent */
export const REGISTRATION_LOAD = 'REGISTRATION_LOAD';
export const REGISTRATION_UPDATE = 'REGISTRATION_UPDATE';
export const REGISTRATION_CREATE = 'REGISTRATION_CREATE';
export const REGISTRATION_REMOVE = 'REGISTRATION_REMOVE';
export const REGISTRATION_OPEN = 'REGISTRATION_OPEN';
export const REGISTRATION_SAVE = 'REGISTRATION_SAVE';
export const REGISTRATION_PROCESS = 'REGISTRATION_PROCESS';

export const loadRegistrationInfo = (idString) => async (dispatch, getState) => {
    const {oidc, minervaSettings, registration} = getState();
    const {portalIdentifier, catalogApiUrl} = minervaSettings;

    if (registration.current?.idString === idString) {
        return;
    }

    dispatch({
        type: REGISTRATION_LOAD,
        async payload() {
            const res = await fetch(
                `${catalogApiUrl}/api/1.0/registrations/${portalIdentifier}/${btoa(idString)}`,
                {
                    headers: getAuthorizationHeaders(oidc.user),
                },
            );

            return await res.json();
        },
    });
};

export const updateRegistration = (key, changes) => (dispatch) => {
    dispatch({
        type: REGISTRATION_UPDATE,
        payload: {
            key: key,
            changes: changes,
        },
    });
};

export const saveRegistrationInfo = () => async (dispatch, getState) => {
    const {oidc, minervaSettings, registration} = getState();
    const {portalIdentifier, catalogApiUrl} = minervaSettings;

    dispatch({
        type: REGISTRATION_SAVE,
        async payload() {
            await fetch(`${catalogApiUrl}/api/1.0/registrations/${portalIdentifier}`, {
                method: 'POST',
                body: JSON.stringify(registration.current),
                headers: {
                    'Content-Type': 'application/json',
                    ...getAuthorizationHeaders(oidc.user),
                },
            });

            return {};
        },
    });
};

const getAuthorizationHeaders = (user) => {
    return user ? {Authorization: `Bearer ${user.access_token}`} : {};
};
