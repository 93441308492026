const initialState = {
    modified: null,
    results: null,
    loading: null,
    error: null,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'ASSIGNMENT_CATALOG_REQUEST': {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case 'ASSIGNMENT_CATALOG_SUCCESS': {
            return {
                ...state,
                modified: Date.now(),
                results: action.payload,
                loading: false,
                error: null,
            };
        }

        case 'ASSIGNMENT_CATALOG_FAILURE': {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        default: {
            return state;
        }
    }
};

export default app;
