import {signal, fetchLearningPlan} from './learningplan.js';

export const EXECUTE_WORKFLOW = 'EXECUTE_WORKFLOW';
export const SIGNAL_WORKFLOW = 'SIGNAL_WORKFLOW';
export const CLOSE_COP = 'CLOSE_COP';
export const RESTART_ELEARNING = 'RESTART_ELEARNING';
export const KICK_ELEARNING = 'KICK_ELEARNING';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const executeWorkflow = (request) => async (dispatch, getState) => {
    const currentInstanceId = getState().learningPlan.instanceId;
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;

    dispatch({
        type: EXECUTE_WORKFLOW,
        async payload() {
            const res = await fetch(`${apiUrl}/api/1.0/workflow/execute`, {
                method: 'POST',
                headers: getHeaders(user),
                body: JSON.stringify(request),
            });

            const data = await res.json();

            // 'Payment Required!'
            if (res.status === 402) {
                const error = new Error('Payment Required');

                error.status = 402;
                error.data = data; // or just `data` if it's coming from somewhere else
                throw error;
            }

            if (!res.ok) {
                const error = new Error('Error occurred in workflow execute');

                console.log(request);
                error.status = res.status;
                error.data = data; // or just `data` if it's coming from somewhere else
                throw error;
            }

            // signal the learningplan when a skip is called to reflect the new learningplan state
            if (request.ActivityType === 'skip') {
                await dispatch(signal(currentInstanceId));
            }

            // if workflow execution results in loading a new instance (subworkflow)
            if (data.workflowInstanceId && currentInstanceId !== data.workflowInstanceId) {
                await dispatch(
                    fetchLearningPlan({
                        instanceId: data.workflowInstanceId,
                        newStart: false,
                    }),
                );
            }

            // set in case of elearning-module
            // also in case of skip
            if (!data.workflowInstanceId) {
                data.workflowInstanceId = currentInstanceId;
            }

            return {
                ...data,
                activityType: request.ActivityType,
            };
        },
    });
};

export const kickElearning = (workflowInstanceId) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;
    // const workflowInstanceId = getState().workflow.workflowInstanceId;

    dispatch({
        type: KICK_ELEARNING,
        async payload() {
            await fetch(`${apiUrl}/api/1.0/workflow/kick?instanceId=${workflowInstanceId}`, {
                method: 'POST',
                headers: getHeaders(user),
                body: {},
            });

            await dispatch(
                fetchLearningPlan({
                    instanceId: workflowInstanceId,
                    newStart: true,
                }),
            );

            return {};
        },
    });
};

export const restartElearning = (targetId) => async (dispatch, getState) => {
    const apiUrl = getState().minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const user = getState().oidc.user;

    dispatch({
        type: RESTART_ELEARNING,
        async payload() {
            const restartResult = await fetch(`${apiUrl}/api/1.0/LearningPlan/ReEnroll`, {
                method: 'POST',
                headers: getHeaders(user),
                body: JSON.stringify({
                    idString: user.profile.idString,
                    learningPlanId: targetId,
                }),
            });
            const {instanceId} = await restartResult.json();

            await dispatch(
                fetchLearningPlan({
                    instanceId: instanceId,
                    newStart: true,
                }),
            );

            return {
                workflowInstanceId: instanceId,
            };
        },
    });
};

export const closeCop = () => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const apiUrl = getState().minervaSettings.portalApiUrl;
    const workflowInstanceId = getState().workflow.workflowInstanceId;

    let {
        selectedUnit,
        itemId,
        itemDefinitionId,
        versionId,
        startDateTime,
        hasResponded,
        copState,
        result,
    } = getState().digitalLearning;

    selectedUnit = selectedUnit || {};

    await dispatch({
        type: CLOSE_COP,
        async payload() {
            await fetch(`${apiUrl}/api/1.0/digitalLearning/closeSession`, {
                method: 'POST',
                headers: getHeaders(user),
                body: JSON.stringify({
                    sessionId: itemId,
                    contentRootId: itemDefinitionId,
                    version: versionId,
                    objectId: selectedUnit.id,
                    contentId: selectedUnit.referenceId,
                    contentVersion: selectedUnit.version,
                    startDateTime: startDateTime,
                    hasResponded: hasResponded || false,
                    result: result,
                    state: copState,
                }),
            });

            await dispatch(signal(workflowInstanceId));

            return {
                itemDefinitionId: null,
            };
        },
    });
};

export const closeDialog = () => async (dispatch, getState) => {
    const workflowInstanceId = getState().workflow.workflowInstanceId;

    await dispatch({
        type: CLOSE_DIALOG,
        async payload() {
            await dispatch(signal(workflowInstanceId));

            return {
                itemDefinitionId: null,
                activityType: null,
            };
        },
    });
};
