/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import {
    ACCREDITATION_LOAD_FORCATALOGITEM,
    ACCREDITATION_LOAD_FORUSER,
    ACCREDITATION_DELETE,
} from '../actions/accreditation.js';

const init = {
    loading: true,
    targetId: null,
    idString: null,
    list: [],
    pageIndex: -1,
};

const app = (state = init, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${ACCREDITATION_LOAD_FORCATALOGITEM}_PENDING`:
        case `${ACCREDITATION_LOAD_FORUSER}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${ACCREDITATION_LOAD_FORCATALOGITEM}_FULFILLED`:
        case `${ACCREDITATION_LOAD_FORUSER}_FULFILLED`:
            return {
                ...state,
                loading: false,
                idString: payload.idString ?? null,
                targetId: payload.targetId ?? null,
                list:
                    payload.pageIndex === 0 ? [...payload.list] : [...state.list, ...payload.list],
                pageIndex: payload.pageIndex,
                total: payload.total,
            };
        case `${ACCREDITATION_LOAD_FORCATALOGITEM}_REJECTED`:
        case `${ACCREDITATION_LOAD_FORUSER}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case `${ACCREDITATION_DELETE}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
                deleteErrorMessage: false,
                deleteSuccessMessage: false,
            };

        case `${ACCREDITATION_DELETE}_FULFILLED`: {
            if (payload && payload.legacyId) {
                const {legacyId} = payload;

                state = {
                    ...state,
                    list: [...state.list.filter((x) => x.legacyId !== legacyId)],
                    deleteSuccessMessage: true,
                };
            }

            return {
                ...state,
                loading: false,
                deleteErrorMessage: false,
            };
        }
        case `${ACCREDITATION_DELETE}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: payload,
                deleteErrorMessage: true,
            };
        default:
            return state;
    }
};

export default app;
