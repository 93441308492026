export const VALIDATE_ENROLLMENTHOOK = 'VALIDATE_ENROLLMENTHOOK';
export const BUY = 'BUY';
export const CUSTOM_BUY = 'CUSTOM_BUY';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const validateEnrollmentHook =
    (targetId, catalogItemSubId) => async (dispatch, getState) => {
        const apiUrl = getState().minervaSettings.portalApiUrl;

        if (!apiUrl) return;

        const user = getState().oidc.user;

        const idString = user.profile.idString;

        dispatch({
            type: VALIDATE_ENROLLMENTHOOK,
            async payload() {
                const res = await fetch(
                    `${apiUrl}/api/1.0/enrollmenthook/validate?targetId=${targetId}&idString=${idString}&subId=${catalogItemSubId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                    },
                );

                const data = await res.json();

                return {
                    ...data,
                    targetId,
                    catalogItemSubId,
                };
            },
        });
    };

export const buy = (targetId, catalogItemSubId) => async (dispatch, getState) => {
    const apiUrl = getState().minervaSettings.portalApiUrl;

    if (!apiUrl) return;

    const user = getState().oidc.user;

    const idString = user.profile.idString;

    dispatch({
        type: BUY,
        async payload() {
            const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            // var closeUrl = encodeURIComponent(`${baseUrl}/costregistration-close-dialog`);
            // var closeUrl = `${baseUrl}/costregistration-close-dialog`;

            const closeUrl = `${window.location}`;

            const res = await fetch(`${apiUrl}/api/1.0/enrollmenthook/buy`, {
                method: 'POST',
                body: JSON.stringify({
                    targetId: targetId,
                    idString: idString,
                    subId: catalogItemSubId,
                    closeUrl: closeUrl,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            const data = await res.json();

            return {
                ...data,
                targetId,
                catalogItemSubId,
            };
        },
    });
};

export const customBuy = (paymentUrl) => async (dispatch) => {
    dispatch({
        type: CUSTOM_BUY,

        async payload() {
            return {
                paymentUrl,
            };
        },
    });
};

export const closeDialog = () => async (dispatch, getState) => {
    const targetId = getState().targetId;
    const catalogItemSubId = getState().catalogItemSubId;

    await dispatch({
        type: CLOSE_DIALOG,
        async payload() {
            return {
                targetId,
                catalogItemSubId,
            };
        },
    });
};
