import moment from 'moment/dist/moment.js';
import {call, debounce, put, putResolve, select, takeEvery, takeLatest} from 'redux-saga/effects';

/**
 * Resets all data and loads the first page using the default (or stored) filters
 * @param {object} action The action
 */
function* doPlanningReportRefresh(action) {
    const fromDate = startDate().fromDate;
    const toDate = startDate().toDate;

    yield call(doSetSearch, {
        payload: {
            fromDate,
            toDate,
            currentPage: 0,
            pageSize: 25,
            results: [],
            selectedCourse: null,
            courseId: null,
            selectedTeacher: null,
            teacherIdString: null,
            selectedLocation: null,
            locationId: null,
            downloading: false,
            onlyWithWarnings: false,
            ...action.payload,
        },
    });
}

function* doSetSearch(action) {
    const {planningReport} = yield select();

    const fromDate = startDate().fromDate;
    const toDate = startDate().toDate;

    if (!planningReport) return;

    const newPlanningReport = {
        fromDate,
        toDate,
        onlyWithWarnings: false,
        downloading: false,
        ...planningReport,
        ...action.payload,
        results: [],
    };

    yield putResolve({
        type: 'PLANNING_REPORT_SETPARAMS',
        payload: {
            ...newPlanningReport,
            loading: true,
            currentPage: 0,
        },
    });
    yield put({type: 'PLANNING_REPORT_PARAMSSET'});
}

function* doPlanningReportLoad() {
    const {minervaSettings, planningReport} = yield select();

    // When pageNumber > 0 we need to check if loading is needed..
    if (planningReport.pageNumber > 0) {
        // When pageSize >= the totalResults returned there must be a filter active so do not fetch more data
        if (planningReport.pageSize >= planningReport.totalCount) return;
        // Only call getData when we know there's more data
        if (
            !planningReport.totalCount ||
            planningReport.results.length >= planningReport.totalCount
        )
            return;
    }

    if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
        return;
    }

    const url = new URL(`${minervaSettings.portalApiUrl}/api/1.0/GetAllSchedules`);

    const fromDate = planningReport.fromDate;
    const toDate = planningReport.toDate;

    // When fromDate is greater than toDate then switch the dates
    if (fromDate && toDate && moment(fromDate) > moment(toDate)) {
        url.searchParams.set('fromDate', toDate);
        url.searchParams.set('toDate', fromDate);
    } else {
        url.searchParams.set('fromDate', fromDate);
        url.searchParams.set('toDate', toDate);
    }

    url.searchParams.set('pageSize', 25);
    url.searchParams.set('currentPage', planningReport.currentPage);

    if (planningReport.teacherIdString) {
        url.searchParams.set('teacherIdString', planningReport.teacherIdString);
    }

    if (planningReport.locationId) {
        url.searchParams.set('locationId', planningReport.locationId);
    }

    if (planningReport.courseId) {
        url.searchParams.set('courseId', planningReport.courseId);
    }

    url.searchParams.set('onlyWithWarnings', planningReport.onlyWithWarnings);

    yield put({type: 'PLANNING_REPORT_LOADING', payload: {...planningReport, loading: true}});

    const response = yield call(fetch, url, {
        headers: minervaSettings.headers,
    });

    const obj = yield call([response, response.json]);
    const appendCurrentResultsToList = planningReport.results.length > 0;
    const results = [...planningReport.results, ...obj.results];

    const resultsUniqueById = [...new Map(results.map((item) => [item['id'], item])).values()];

    const payload = {
        ...obj,
        appendCurrentResultsToList,
        results: resultsUniqueById,
        currentResults: obj.results,
        currentPage: planningReport.currentPage + 1,
        loading: false,
    };

    yield put({type: 'PLANNING_REPORT_LOADED', payload: payload});
}

function* doDebouncePlanningReportLoad() {
    yield put({type: 'PLANNING_REPORT_LOAD'});
}

function* doPlanningReport(action) {
    let planningReport = yield select((x) => x.planningReport);
    const payload = {
        ...planningReport,
        downloading: true,
    };

    yield put({type: 'REPORT_FETCH_BUSY', payload: payload});

    let length = planningReport.results.length;
    let totalCount = planningReport.totalCount;

    while (length !== totalCount) {
        yield doPlanningReportLoad();
        planningReport = yield select((x) => x.planningReport);
        length = planningReport.results.length;
        totalCount = planningReport.totalCount;
    }
    const items = determineWeeksForSchedules(
        planningReport.results,
        action.data.latestYear,
        action.data.latestWeek,
    );
    const data = {
        ...action.data,
        items,
    };

    yield put({...action, type: 'REPORT_FETCH', data});
}

function dateToUtc(date, wholeDay = false) {
    if (!date) return date;

    const utcDate = moment.utc(date);

    if (wholeDay) {
        utcDate.add(1, 'days');
    }

    return utcDate.toISOString();
}
function startDate() {
    const end = new Date();

    const endPlusSevenDays = end.setDate(end.getDate() + 7);

    const fromDate = dateToUtc(new Date());
    const toDate = dateToUtc(endPlusSevenDays, true);

    return {fromDate, toDate};
}
function determineWeeksForSchedules(data, latestYear, latestWeek) {
    const weeksAndSchedules = [];

    for (let i = 0; i < data.length; i++) {
        const schedule = data[i];
        const year = moment(new Date(schedule.startDateTime)).year();
        const week = moment(new Date(schedule.startDateTime)).week();

        if (year > latestYear || (year === latestYear && week > latestWeek)) {
            latestYear = year;
            latestWeek = week;

            weeksAndSchedules.push({
                type: 'header',
                year: year,
                week: week,
                schedule: null,
            });

            weeksAndSchedules.push({
                type: 'columns',
                year: -1,
                week: -1,
                schedule: null,
            });
        }

        weeksAndSchedules.push({
            type: 'item',
            year: -1,
            week: -1,
            schedule: schedule,
        });
    }

    return weeksAndSchedules;
}
export function* planningReportSaga() {
    yield takeEvery('PLANNING_REPORT_SETSEARCH', doSetSearch);
    yield takeLatest('PLANNING_REPORT_REFRESH', doPlanningReportRefresh);
    yield takeLatest('PLANNING_REPORT_LOAD', doPlanningReportLoad);
    yield takeLatest('PLANNING_REPORT_DOWNLOAD', doPlanningReport);
    yield debounce(1000, 'PLANNING_REPORT_PARAMSSET', doDebouncePlanningReportLoad);
}
