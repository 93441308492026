import {html, css, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {tcgLocalizeBehaviorMixin} from './localize/tcg-localize-behavior.js';
import sharedStyles from './styles/tcg-shared-styles.js';
import '@sdblearning/sdb-learning-frontend';

@customElement('aca-old-browser')
class AcaOldBrowser extends tcgLocalizeBehaviorMixin(LitElement) {
    static styles = [
        sharedStyles,
        css`
            sdb-content-card {
                max-width: 600px;
                margin: 24px;
                flex-grow: 0;
            }

            .browser-icons {
                height: 30px;
                width: 30px;
                opacity: 0.7;
                padding-bottom: 5px;
            }

            .cancel-icon {
                height: 24px;
                width: 24px;
                opacity: 0.7;
            }

            .alignRow {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
            }

            .alignColumn {
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }

            .closeButton {
                position: absolute;
                right: 5px;
                top: 5px;
                cursor: pointer;
            }
        `,
    ];

    @state() accessor _showDialog = false;

    render() {
        return html`
            <sdb-content-card ?hidden=${!this._showDialog}>
                <div class="card-content">
                    <div class="closeButton">
                        <sdb-content-icon
                            icon="fal fa-xmark"
                            class="cancel-icon"
                            @click=${(e) => this.closeMessage(e)}
                        ></sdb-content-icon>
                    </div>
                    <div
                        class="tcg-H5-black"
                        style="padding-bottom: 10px;"
                    >
                        ${this.localize('unsupportedBrowserHeader')}
                    </div>
                    <div class="tcg-subtitle-2-black">
                        ${this.localize('unsupportedBrowserMessage')}
                    </div>
                    <div>
                        <div class="alignRow">
                            <div class="alignColumn">
                                <sdb-content-icon
                                    icon="brands fa-chrome"
                                    class="browser-icons"
                                ></sdb-content-icon>
                                <div class="tcg-subtitle-2-black">Google Chrome</div>
                            </div>
                            <div class="alignColumn">
                                <sdb-content-icon
                                    icon="brands fa-firefox"
                                    class="browser-icons"
                                ></sdb-content-icon>
                                <div class="tcg-subtitle-2-black">Firefox</div>
                            </div>
                            <div class="alignColumn">
                                <sdb-content-icon
                                    icon="brands fa-edge"
                                    class="browser-icons"
                                ></sdb-content-icon>
                                <div class="tcg-subtitle-2-black">Microsoft Edge</div>
                            </div>
                        </div>
                    </div>
                </div>
            </sdb-content-card>
        `;
    }

    firstUpdated() {
        this._showDialog = this._checkBrowserIsIE11();
    }

    _checkBrowserIsIE11() {
        if (!!window.MSInputMethodContext && !!document.documentMode) {
            if (
                !localStorage.hideUnsupportedBrowserDialog ||
                localStorage.hideUnsupportedBrowserDialog == 'false'
            ) {
                return true;
            }
        }
        return false;
    }

    _closeMessage() {
        if (typeof Storage !== 'undefined') {
            localStorage.setItem('hideUnsupportedBrowserDialog', this._showDialog);
        } else {
            console.log('No localStorage support..');
        }
        this.set('_showDialog', !this._showDialog);
    }
}
