import {
    ADD_ASSESSOR,
    REMOVE_ASSESSOR,
    HIDE_NOT_ASSIGNED_TOGGLE_FILTER,
    SET_CURRENT_CLUSTER_TYPE,
    SELECT_USER_FUNCTION_FOR_FILTER,
    SET_SKILL_FILTER_PARAMS_FOR_DEPARTMENTS,
    SET_SELECTED_FILTER_LABELS,
    SET_USER_SKILLS_HISTORY_VIEW,
} from '../actions/skillsSettings.js';

const app = (state = {currentClusterType: '', userSkillsHistoryActive: false}, action) => {
    switch (action.type) {
        case 'SKILLSSETTINGS_RECEIVED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case `${ADD_ASSESSOR}_PENDING`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${ADD_ASSESSOR}_FULFILLED`: {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                saved: true,
            };
        }
        case `${ADD_ASSESSOR}_REJECTED`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${REMOVE_ASSESSOR}_PENDING`: {
            return {
                ...state,
                saved: false,
            };
        }
        case `${REMOVE_ASSESSOR}_FULFILLED`: {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                saved: true,
            };
        }
        case `${REMOVE_ASSESSOR}_REJECTED`: {
            return {
                ...state,
                saved: false,
            };
        }
        case HIDE_NOT_ASSIGNED_TOGGLE_FILTER: {
            return {
                ...state,
                hideNotAssigned: action.payload,
            };
        }
        case SET_CURRENT_CLUSTER_TYPE: {
            return {
                ...state,
                currentClusterType: action.payload,
            };
        }
        case SELECT_USER_FUNCTION_FOR_FILTER: {
            return {
                ...state,
                functionId: action.payload,
            };
        }
        case SET_SKILL_FILTER_PARAMS_FOR_DEPARTMENTS: {
            return {
                ...state,
                skillFilterParams: action.payload,
            };
        }
        case SET_SELECTED_FILTER_LABELS: {
            return {
                ...state,
                selectedFiltersLabels: action.payload,
            };
        }
        case SET_USER_SKILLS_HISTORY_VIEW: {
            return {
                ...state,
                userSkillsHistoryActive: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default app;
