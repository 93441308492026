import {UPDATE_CATALOG_ITEM, STORE_SELECTED_CATALOG_ITEM} from '../actions/catalogItem.js';

const app = (state = {}, action) => {
    switch (action.type) {
        case '`${UPDATE_CATALOG_ITEM}`': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case `${UPDATE_CATALOG_ITEM}_PENDING`: {
            return {
                ...state,
                loading: true,
            };
        }
        case `${UPDATE_CATALOG_ITEM}_FULFILLED`: {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                loading: false,
            };
        }
        case `${UPDATE_CATALOG_ITEM}_REJECTED`: {
            return {
                ...state,
                loading: false,
            };
        }
        case STORE_SELECTED_CATALOG_ITEM: {
            return {
                ...state,
                itemIndex: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default app;
