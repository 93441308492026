export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_REVIEWER = 'ADD_REVIEWER';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const REMOVE_REVIEWER = 'REMOVE_REVIEWER';

const getHeaders = (user) => {
    if (user) {
        return {
            Authorization: `Bearer ${user.access_token}`,
        };
    } else return {};
};

export const saveSettings = (settings) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.settingsUrl}/api/settings/${minervaSettings.portalIdentifier}`;

    // convert enabledFeatureFlags back to string array to prevent 500 internal server error
    const temp = {...settings, enabledFeatureFlags: Object.keys(settings.enabledFeatureFlags)};

    const body = JSON.stringify(temp);

    await dispatch({
        type: SAVE_SETTINGS,
        async payload() {
            const response = await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: body,
            });

            const savedSettings = await response.json();

            return savedSettings;
        },
    });
};

export const addAdmin = (user) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.settingsUrl}/api/admin/${minervaSettings.portalIdentifier}`;

    dispatch({
        type: ADD_ADMIN,
        async payload() {
            await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify(user),
            });

            await dispatch({type: 'MINERVASETTINGS_REQUESTED'});
        },
    });
};

export const addReviewer = (user) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.settingsUrl}/api/departmentReviewer/${minervaSettings.portalIdentifier}`;

    dispatch({
        type: ADD_REVIEWER,
        async payload() {
            await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify(user),
            });

            await dispatch({type: 'MINERVASETTINGS_REQUESTED'});
        },
    });
};

export const removeAdmin = (idString) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.settingsUrl}/api/admin/${minervaSettings.portalIdentifier}?idString=${encodeURIComponent(idString)}`;

    dispatch({
        type: REMOVE_ADMIN,
        async payload() {
            await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });

            await dispatch({type: 'MINERVASETTINGS_REQUESTED'});
        },
    });
};

export const removeReviewer = (idString) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const url = `${minervaSettings.settingsUrl}/api/departmentReviewer/${minervaSettings.portalIdentifier}?idString=${encodeURIComponent(idString)}`;

    dispatch({
        type: REMOVE_REVIEWER,
        async payload() {
            await fetch(url, {
                headers: {
                    ...getHeaders(oidc.user),
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });

            await dispatch({type: 'MINERVASETTINGS_REQUESTED'});
        },
    });
};
