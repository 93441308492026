export const UPDATE_CATALOG_ITEM = 'UPDATE_CATALOG_ITEM';
export const STORE_SELECTED_CATALOG_ITEM = 'STORE_SELECTED_CATALOG_ITEM';

export const updateCatalogItem = (ci) => (dispatch) => {
    dispatch({
        type: UPDATE_CATALOG_ITEM,
        payload: ci,
    });
};

export const storeSelectedCatalogItem = (itemIndex) => {
    return {
        type: STORE_SELECTED_CATALOG_ITEM,
        payload: itemIndex,
    };
};
