import {
    FETCH_LEARNINGPLAN,
    NAVIGATE_PARENTFLOW,
    SIGNAL_LEARNINGPLAN,
} from '../actions/learningplan';
import {setVisibility} from '../util/nodeVisibility';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${FETCH_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${FETCH_LEARNINGPLAN}_FULFILLED`: {
            const sequence = payload.sequence;
            const trackingRecords = payload.trackingRecords;

            const isTester = payload.isTester;

            setVisibility(sequence, trackingRecords, isTester);

            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${FETCH_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${NAVIGATE_PARENTFLOW}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${NAVIGATE_PARENTFLOW}_FULFILLED`: {
            const sequence = payload.sequence;
            const trackingRecords = payload.trackingRecords;

            const isTester = payload.isTester;

            setVisibility(sequence, trackingRecords, isTester);

            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${NAVIGATE_PARENTFLOW}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${SIGNAL_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${SIGNAL_LEARNINGPLAN}_FULFILLED`: {
            const sequence = JSON.parse(JSON.stringify(state.sequence));
            const trackingRecords = payload.trackingRecords;

            const isTester = state.isTester;

            setVisibility(sequence, trackingRecords, isTester);

            return {
                ...state,
                ...payload,
                sequence: sequence,
                isLoading: false,
            };
        }
        case `${SIGNAL_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default app;
