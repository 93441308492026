import {sortOnDate} from '../utils/classroom.js';
import {CLASSROOM_LOADPLANNINGS} from '../actions/classroom.js';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${CLASSROOM_LOADPLANNINGS}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${CLASSROOM_LOADPLANNINGS}_FULFILLED`:
            return {
                ...state,
                loading: false,
                plannings: sortOnDate([...payload.plannings], 'startDateTime', true),
            };
        case `${CLASSROOM_LOADPLANNINGS}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export default app;
