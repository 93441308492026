/* eslint-disable indent */
export const ACCREDITATION_LOAD_FORCATALOGITEM = 'ACCREDITATION_LOAD_FORCATALOGITEM';
export const ACCREDITATION_LOAD_FORUSER = 'ACCREDITATION_LOAD_FORUSER';
export const ACCREDITATION_DELETE = 'ACCREDITATION_DELETE';

const PAGESIZE = 30;

export const loadAccreditationsForCatalogItem = (catalogItem) => async (dispatch, getState) => {
    const {oidc, minervaSettings, accreditation} = getState();
    const {portalIdentifier, catalogApiUrl} = minervaSettings;
    const {targetId} = catalogItem;

    const pageIndex = targetId === accreditation.targetId ? accreditation.pageIndex + 1 : 0;

    dispatch({
        type: ACCREDITATION_LOAD_FORCATALOGITEM,
        async payload() {
            const params = new URLSearchParams({
                pageIndex: pageIndex,
                pageSize: PAGESIZE,
            });

            const res = await fetch(
                `${catalogApiUrl}/api/1.0/accreditations/catalogitem/${portalIdentifier}/${targetId}?${params}`,
                {
                    headers: getAuthorizationHeaders(oidc.user),
                },
            );

            const response = await res.json();

            return {
                targetId: targetId,
                list: response.results,
                pageIndex: pageIndex,
                total: response.count,
            };
        },
    });
};

export const deleteAccreditation = (accreditation) => async (dispatch, getState) => {
    const {oidc, minervaSettings} = getState();
    const {portalIdentifier, accreditationApiUrl} = minervaSettings;

    const {legacyId} = accreditation;

    dispatch({
        type: ACCREDITATION_DELETE,
        async payload() {
            const res = await fetch(
                `${accreditationApiUrl}/api/2.0/accreditation/${legacyId}/${portalIdentifier}`,
                {
                    method: 'DELETE',
                    headers: getAuthorizationHeaders(oidc.user),
                },
            );

            if (res.status === 200) {
                return {legacyId};
            } else {
                await dispatch({type: 'ACCREDITATION_DELETE_REJECTED', payload: res.statusText});
            }
        },
    });
};

export const loadAccreditationsForUser = (idString) => async (dispatch, getState) => {
    const {oidc, minervaSettings, accreditation} = getState();
    const {portalIdentifier, catalogApiUrl} = minervaSettings;

    const pageIndex = idString === accreditation.idString ? accreditation.pageIndex + 1 : 0;

    dispatch({
        type: ACCREDITATION_LOAD_FORUSER,
        async payload() {
            const params = new URLSearchParams({
                pageIndex: pageIndex,
                pageSize: PAGESIZE,
            });

            const res = await fetch(
                `${catalogApiUrl}/api/1.0/accreditations/${portalIdentifier}/${btoa(idString)}?${params}`,
                {
                    headers: getAuthorizationHeaders(oidc.user),
                },
            );

            const response = await res.json();

            return {
                idString: idString,
                list: response.results,
                pageIndex: pageIndex,
                total: response.count,
            };
        },
    });
};

const getAuthorizationHeaders = (user) => {
    return user ? {Authorization: `Bearer ${user.access_token}`} : {};
};
