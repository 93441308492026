//import ScormAPI12Adapter from '@competencegroup/tcg.scorm/dist/ScormAPI12Adapter';

import {updateScormResult} from './scorm-result';

export const INIT_API = 'INIT_API';

export const START_SCORM = 'START_SCORM';
export const COMMIT_SCORM = 'COMMIT_SCORM';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

const initializeScormApiAdapter = (preview, defaults) => async (dispatch, getState) => {
    dispatch({
        type: INIT_API,
        async payload() {
            const scormApi = null;
            // new ScormAPI12Adapter({
            //     debug: false,
            //     defaults: defaults,
            // });

            // Don't hook up the save events when previewing the scorm package
            if (!preview) {
                scormApi.onLmsFinish.on(async () => {
                    const data = scormApi.scoData.cmiElements;

                    await dispatch(queueCommitScorm(data, true, true));
                });

                scormApi.onLmsCommit.on(async (data) => {
                    await dispatch(queueCommitScorm(data, false, false));
                });
            }

            window.API = scormApi;

            return {
                scormApi,
            };
        },
    });
};

export const startScorm = (itemId, preview, targetId, reset) => async (dispatch, getState) => {
    const user = getState().oidc.user;
    const catalogApiUrl = getState().minervaSettings.catalogApiUrl;
    const portalIdentifier = getState().minervaSettings.portalIdentifier;
    const lowercasePortalIdentifier = portalIdentifier.toLowerCase();

    dispatch({
        type: START_SCORM,
        async payload() {
            // Fetch the ScormItem from Raven
            const res = await fetch(`${catalogApiUrl}/api/scorm/${portalIdentifier}/${itemId}`, {
                method: 'GET',
                headers: getHeaders(user),
            });

            const scormItem = await res.json();
            const uniqueId = scormItem.uniqueId;
            const startPage = scormItem.startPage;

            let defaults = [
                {name: 'studentId', value: user.profile.idString},
                {name: 'studentName', value: user.profile.name},
            ];

            if (preview) {
                defaults = [
                    {name: 'lessonMode', value: 'browse'},
                    {name: 'studentId', value: user.profile.idString},
                    {name: 'studentName', value: user.profile.name},
                ];
            }

            if (!preview && !reset) {
                const scormResult = getState().scormResult.scormResult;

                // scormResult.entry = 'resume';
                // scormResult.lessonMode = 'review';
                // scormResult.suspendData = '';

                // scormResult.credit = 'credit';

                defaults = [
                    {name: 'entry', value: scormResult.entry},
                    {name: 'lessonMode', value: scormResult.lessonMode},
                    {name: 'lessonLocation', value: scormResult.lessonLocation},
                    {name: 'suspendData', value: scormResult.suspendData},
                    {name: 'launchData', value: undefined},
                    {name: 'lessonStatus', value: scormResult.lessonStatus},

                    {name: 'studentId', value: user.profile.idString},
                    {name: 'studentName', value: user.profile.name},
                ];
            }

            // construct the scormUrl
            const scormUrl =
                '/scorm-az/academyscorm.blob.core.windows.net/unzippedscorm/' +
                lowercasePortalIdentifier +
                '/' +
                uniqueId +
                '/' +
                startPage;

            await dispatch(initializeScormApiAdapter(preview, defaults));

            return {
                action: 'redirectInDialog',
                externalId: itemId,
                scormUrl,
                targetId,
                isPreview: preview,
            };
        },
    });
};

export const queueCommitScorm = (data, finish, lmsFinish) => {
    return {
        queue: 'COMMIT_SCORM',
        callback: async (next, dispatch, getState) => {
            if (data) {
                await saveScorm(getState(), data, dispatch);
            }

            if (finish) {
                await saveScormWithStatement(getState(), data, dispatch);

                // //TODO: Do we need a finish method ???
                // await finishScorm(getState());

                if (lmsFinish) {
                    dispatch(closeDialog());
                }
            }

            next();
        },
    };
};

const saveScorm = async (state, data, dispatch) => {
    const apiUrl = state.minervaSettings.catalogApiUrl;

    const user = state.oidc.user;
    const targetId = state.scorm.targetId;
    const externalId = state.scorm.externalId;

    if (targetId && externalId) {
        const res = await fetch(`${apiUrl}/api/scorm12/result`, {
            headers: getHeaders(state.oidc.user),
            method: 'PUT',
            body: JSON.stringify({
                cmiElements: data.map((el) => {
                    return {name: el.name, value: el.value + ''};
                }),
                params: [],

                targetId: targetId,
                objectId: externalId,

                studentId: user.profile.idString,
                studentName: user.profile.name,
            }),
        });

        if (res.status === 201) {
            // const scormResult =
            await res.json();

            // await dispatch(updateScormResult(scormResult));
        }
    }
};

const saveScormWithStatement = async (state, data, dispatch) => {
    const apiUrl = state.minervaSettings.catalogApiUrl;
    const portalIdentifier = state.minervaSettings.portalIdentifier;

    const user = state.oidc.user;

    const targetId = state.scorm.targetId;
    const externalId = state.scorm.externalId;

    if (targetId && externalId) {
        const res = await fetch(`${apiUrl}/api/scorm12/result/${portalIdentifier}`, {
            headers: getHeaders(state.oidc.user),
            method: 'PUT',
            body: JSON.stringify({
                cmiElements: data.map((el) => {
                    return {name: el.name, value: el.value + ''};
                }),

                params: [],

                targetId: targetId,
                objectId: externalId,

                studentId: user.profile.idString,
                studentName: user.profile.name,
            }),
        });

        if (res.status === 201) {
            const scormResult = await res.json();

            await dispatch(updateScormResult(scormResult));
        }
    }
};

export const closeDialog = () => async (dispatch, getState) => {
    await dispatch({
        type: CLOSE_DIALOG,
        async payload() {
            return {};
        },
    });
};
