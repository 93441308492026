import {LitElement} from 'lit';
import {store} from '../store.js';
import userManager from '../utils/userManager';

class SilentRenewCallback extends LitElement {
    firstUpdated() {
        userManager
            .signinSilentCallback()
            .then((result) => {
                store.dispatch({type: 'SIGNEDIN', url: result.state.url});
            })
            .catch((err) => {
                // userManager.signinRedirect();
            });
    }
}

customElements.define('silent-renew-callback', SilentRenewCallback);
