import {
    EXECUTE_WORKFLOW,
    CLOSE_COP,
    RESTART_ELEARNING,
    KICK_ELEARNING,
    CLOSE_DIALOG,
} from '../actions/workflow';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${EXECUTE_WORKFLOW}_PENDING`:
            return {
                ...state,
                isLoading: true,
            };
        case `${EXECUTE_WORKFLOW}_FULFILLED`:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case `${EXECUTE_WORKFLOW}_REJECTED`: {
            // TODO: Move to component?
            if (payload?.status === 402) {
                if (payload.redirectUrl) {
                    window.location.href = payload.redirectUrl;
                } else {
                    alert('Dit product moet afgerekend worden');
                }
            } else {
                alert('Error executing workflow');

                // TODO: log to insights?
                console.error(payload?.status, payload?.data, payload?.message);
            }

            return {
                ...state,
                isLoading: false,
            };
        }

        case `${RESTART_ELEARNING}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${RESTART_ELEARNING}_FULFILLED`: {
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${RESTART_ELEARNING}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${KICK_ELEARNING}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${KICK_ELEARNING}_FULFILLED`: {
            const p = payload;

            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${KICK_ELEARNING}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${CLOSE_COP}_FULFILLED`:
        case `${CLOSE_DIALOG}_FULFILLED`: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
};

export default app;
