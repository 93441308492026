const initialState = {
    signedIn: null,
    pending: null,
    error: null,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGNIN_FULFILLED':
        case 'SIGNIN_SILENT_FULFILLED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                pending: false,
                signedIn: true,
            };
        }

        case 'SIGNIN_SILENT_REJECTED': {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
                pending: false,
                signedIn: !!state.signedIn,
            };
        }

        default: {
            return state;
        }
    }
};

export default app;
